import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Tooltip,
    ModalHeader
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';

import './project.css';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const CreateCase = () => {
    document.title = "Create Case";
    const navigate = useNavigate();
    
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [CaseType, setCaseType] = useState("");
    const [OfficerID, setOfficerID] = useState("");
    const [CaseStatus, setCaseStatus] = useState("");
    const [Description, setDescription] = useState("");
    const [Department, setDepartment] = useState("");
    
    const [complainantName, setComplainantName] = useState("");
    const [complainantContact, setComplainantContact] = useState("");
    const [complainantAddress, setComplainantAddress] = useState("");
    const [complainantIDNumber, setComplainantIDNumber] = useState("");
    const [complainantDOB, setComplainantDOB] = useState("");
    const [complainantGender, setComplainantGender] = useState("");
    const [complainantOtherDetails, setComplainantOtherDetails] = useState("");
    const [complainantRelation, setComplainantRelation] = useState("");

    const [accusedName, setAccusedName] = useState("");
    const [accusedContact, setAccusedContact] = useState("");
    const [accusedAddress, setAccusedAddress] = useState("");
    const [accusedIDNumber, setAccusedIDNumber] = useState("");
    const [accusedDOB, setAccusedDOB] = useState("");
    const [accusedGender, setAccusedGender] = useState("");
    const [accusedOtherDetails, setAccusedOtherDetails] = useState("");
    const [accusedRelation, setAccusedRelation] = useState("");

    const [users, setUsers] = useState([]);

    const getUsers = async () => {
      const data = await get(`user/find`);
      setUsers(data);
    }

    useEffect(()=>{
      getUsers();
    },[]);

    const addCase = async () => {
      const data = {
        case: {
          CaseType,
          OfficerID,
          CaseStatus,
          Description,
          Department
        },
        complainant: {
          Name: complainantName,
          Contact: complainantContact,
          Address: complainantAddress,
          IDNumber: complainantIDNumber,
          DOB: complainantDOB,
          Gender: complainantGender,
          OtherDetails: complainantOtherDetails,
          Relation: complainantRelation
        },
        accused: {
          Name: accusedName,
          Contact: accusedContact,
          Address: accusedAddress,
          IDNumber: accusedIDNumber,
          DOB: accusedDOB,
          Gender: accusedGender,
          OtherDetails: accusedOtherDetails,
          Relation: accusedRelation
        }
      }

      await post('case/create', data);
      navigate('/case-management');
    }
    
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const caseTypes = [
      "Theft",
      "Assault",
      "Fraud",
      "Burglary",
      "Arson",
      "Kidnapping",
      "Homicide",
      "Drug Trafficking",
      "Vandalism",
      "Robbery"
    ];
    
    const caseStatuses = [
      "Pending Verification",
      "Under Investigation",
      "Closed",
      "Evidence Collection",
      "Awaiting Trial",
      "Rescue Operation Ongoing",
      "Under Investigation",
      "Surveillance Ongoing",
      "Closed",
      "Suspect Apprehended"
    ];
    
    const caseDepartments = [
      "Criminal Investigation",
      "Violent Crimes",
      "Cyber Crimes",
      "Property Crimes",
      "Fire & Safety",
      "Special Task Force",
      "Homicide Unit",
      "Narcotics",
      "Community Policing",
      "Rapid Response Unit"
    ];    
    
    const officers = [
      "Professor",
      "Officer Murphy",
      "Detective Harper",
      "Detective Holmes",
      "Sergeant Ross",
      "Agent Carter",
      "Detective Morgan",
      "Agent Johnson",
      "Officer Thompson",
      "Captain Reynolds"
    ];    

    return (
        <React.Fragment>

            <div className="page-content">
                <h5 style={{paddingTop: '20px'}}>Case Management</h5>
                <p>Case Management <b>&gt;</b> Create Case</p>
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <Row>
                        <Col sm={4}>
                            <h5>
                                Create Case
                                <i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
                            </h5>

                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="pageHeading"
                                toggle={toggleTooltip}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
                            </Tooltip>

                            </Col>
                    </Row>

                    <Row className="mb-4 mt-4">
                        <Col sm={12}>
                          <h4>Case Details</h4>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="CaseType" className="me-4 w-50">Case Type</Label>
                              <Input
                                  type="select"
                                  id="CaseType"
                                  onChange={(e)=> setCaseType(e.target.value)}
                                  value={CaseType}
                                  placeholder="Select"
                              >
                                <option>Select</option>
                                {caseTypes.map(type=>(
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="OfficerID" className="me-4 w-50">Select Officer</Label>
                              <Input
                                  type="select"
                                  id="OfficerID"
                                  onChange={(e)=> setOfficerID(e.target.value)}
                                  value={OfficerID}
                                  placeholder="Select"
                              >
                                <option>Select</option>
                                {users.filter(x=> x.role.Name == 'Case Officer').map(user=>(
                                  <option key={user.UserID} value={user.UserID}>{user.Name}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="CaseStatus" className="me-4 w-50">Case Status</Label>
                              <Input
                                  type="select"
                                  id="CaseStatus"
                                  onChange={(e)=> setCaseStatus(e.target.value)}
                                  value={CaseStatus}
                                  placeholder="Select"
                              >
                                <option>Select</option>
                                <option value={'Awaiting Case Verification'}>Awaiting Case Verification</option>
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="Description" className="me-4 w-50">Case Description</Label>
                              <textarea
                                  type="text"
                                  id="Description"
                                  onChange={(e)=> setDescription(e.target.value)}
                                  value={Description}
                                  rows={4}
                                  cols={200}
                                  placeholder="Type here..."
                                  style={{borderColor: '#e9ecef'}}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="Department" className="me-4 w-50">Department</Label>
                              <Input
                                  type="select"
                                  id="Department"
                                  onChange={(e)=> setDepartment(e.target.value)}
                                  value={Department}
                                  placeholder="Select"
                              >
                                <option>Select</option>
                                {caseDepartments.map(type=>(
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-4 mt-4">
                        <Col sm={12}>
                          <h4>Complainant Details</h4>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="complainantName" className="me-4 w-50">Complainant Name</Label>
                              <Input
                                  type="text"
                                  id="complainantName"
                                  onChange={(e)=> setComplainantName(e.target.value)}
                                  value={complainantName}
                                  placeholder="Type here"
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Complainant Contact</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantContact(e.target.value)}
                                  value={complainantContact}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Complainant Address</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantAddress(e.target.value)}
                                  value={complainantAddress}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">ID Number</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantIDNumber(e.target.value)}
                                  value={complainantIDNumber}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">DOB</Label>
                              <Input
                                  type="date"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantDOB(e.target.value)}
                                  value={complainantDOB}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Gender</Label>
                              <Input
                                  type="select"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantGender(e.target.value)}
                                  value={complainantGender}
                              >
                                <option disabled selected value={""}>Select Gender</option>
                                <option value={"male"}>Male</option>
                                <option value={"female"}>Female</option>
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Other Details</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantOtherDetails(e.target.value)}
                                  value={complainantOtherDetails}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Relation to Accused</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantRelation(e.target.value)}
                                  value={complainantRelation}
                              />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row className="mb-4 mt-4">
                        <Col sm={12}>
                          <h4>Accused Details</h4>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="AccusedName" className="me-4 w-50">Accused Name</Label>
                              <Input
                                  type="text"
                                  id="AccusedName"
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedName(e.target.value)}
                                  value={accusedName}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Accused Contact</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedContact(e.target.value)}
                                  value={accusedContact}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Accused Address</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedAddress(e.target.value)}
                                  value={accusedAddress}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">ID Number</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedIDNumber(e.target.value)}
                                  value={accusedIDNumber}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">DOB</Label>
                              <Input
                                  type="date"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedDOB(e.target.value)}
                                  value={accusedDOB}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Gender</Label>
                              <Input
                                  type="select"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedGender(e.target.value)}
                                  value={accusedGender}
                                >
                                <option disabled selected value={""}>Select Gender</option>
                                <option value={"male"}>Male</option>
                                <option value={"female"}>Female</option>
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Other Details</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedOtherDetails(e.target.value)}
                                  value={accusedOtherDetails}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Relation to Complainant</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedRelation(e.target.value)}
                                  value={accusedRelation}
                              />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                          <div className="text-end">
                            <button className="btn btn-primary" onClick={addCase}>Add Case</button>
                          </div>
                      </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateCase;
