import React, { useEffect, useMemo, useState } from "react";
import {
  Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
  Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader,
  Tooltip
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get, post, postForm } from "helpers/api_helper";
import moment from 'moment';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx';

const formatDateTime = (isoDate) => {
  return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const CaseManagement = () => {
  document.title = "Case Management";

  const [data, setData] = useState([]);
  // const data = [
  //   {
  //     CaseID: 1,
  //     CaseType: "Theft",
  //     CreatedDate: "20/12/2024 10:25:00",
  //     CaseStatus: "Pending Verification",
  //     Description: "Stolen wallet reported in the mall.",
  //     Department: "Criminal Investigation",
  //     ComplainantName: "John Doe",
  //     AccusedName: "Michael Johnson",
  //     AssignedOfficer: "Professor",
  //   },
  //   {
  //     CaseID: 2,
  //     CaseType: "Assault",
  //     CreatedDate: "19/12/2024 15:45:00",
  //     CaseStatus: "Under Investigation",
  //     Description: "Physical altercation at the local bar.",
  //     Department: "Violent Crimes",
  //     ComplainantName: "Anna Smith",
  //     AccusedName: "David Brown",
  //     AssignedOfficer: "Officer Murphy",
  //   },
  //   {
  //     CaseID: 3,
  //     CaseType: "Fraud",
  //     CreatedDate: "18/12/2024 09:10:00",
  //     CaseStatus: "Closed",
  //     Description: "Online scam involving credit card fraud.",
  //     Department: "Cyber Crimes",
  //     ComplainantName: "Emily Davis",
  //     AccusedName: "Sophia Wilson",
  //     AssignedOfficer: "Detective Harper",
  //   },
  //   {
  //     CaseID: 4,
  //     CaseType: "Burglary",
  //     CreatedDate: "17/12/2024 22:30:00",
  //     CaseStatus: "Evidence Collection",
  //     Description: "Break-in at residential premises.",
  //     Department: "Property Crimes",
  //     ComplainantName: "James Taylor",
  //     AccusedName: "Unknown",
  //     AssignedOfficer: "Detective Holmes",
  //   },
  //   {
  //     CaseID: 5,
  //     CaseType: "Arson",
  //     CreatedDate: "16/12/2024 13:20:00",
  //     CaseStatus: "Awaiting Trial",
  //     Description: "Fire at a commercial building.",
  //     Department: "Fire & Safety",
  //     ComplainantName: "Robert Lee",
  //     AccusedName: "Christopher Martinez",
  //     AssignedOfficer: "Sergeant Ross",
  //   },
  //   {
  //     CaseID: 6,
  //     CaseType: "Kidnapping",
  //     CreatedDate: "15/12/2024 18:00:00",
  //     CaseStatus: "Rescue Operation Ongoing",
  //     Description: "Reported kidnapping of a minor.",
  //     Department: "Special Task Force",
  //     ComplainantName: "Sarah Walker",
  //     AccusedName: "Unknown",
  //     AssignedOfficer: "Agent Carter",
  //   },
  //   {
  //     CaseID: 7,
  //     CaseType: "Homicide",
  //     CreatedDate: "14/12/2024 05:45:00",
  //     CaseStatus: "Under Investigation",
  //     Description: "Suspicious death in a rural area.",
  //     Department: "Homicide Unit",
  //     ComplainantName: "Laura Young",
  //     AccusedName: "Tommy Anderson",
  //     AssignedOfficer: "Detective Morgan",
  //   },
  //   {
  //     CaseID: 8,
  //     CaseType: "Drug Trafficking",
  //     CreatedDate: "13/12/2024 11:15:00",
  //     CaseStatus: "Surveillance Ongoing",
  //     Description: "Illegal substance trade suspected.",
  //     Department: "Narcotics",
  //     ComplainantName: "Charles King",
  //     AccusedName: "William Scott",
  //     AssignedOfficer: "Agent Johnson",
  //   },
  //   {
  //     CaseID: 9,
  //     CaseType: "Vandalism",
  //     CreatedDate: "12/12/2024 16:50:00",
  //     CaseStatus: "Closed",
  //     Description: "Graffiti on public property.",
  //     Department: "Community Policing",
  //     ComplainantName: "Elizabeth Harris",
  //     AccusedName: "Ethan Clark",
  //     AssignedOfficer: "Officer Thompson",
  //   },
  //   {
  //     CaseID: 10,
  //     CaseType: "Robbery",
  //     CreatedDate: "11/12/2024 20:40:00",
  //     CaseStatus: "Suspect Apprehended",
  //     Description: "Armed robbery at a convenience store.",
  //     Department: "Rapid Response Unit",
  //     ComplainantName: "Nathan Lopez",
  //     AccusedName: "Alexander Garcia",
  //     AssignedOfficer: "Captain Reynolds",
  //   },
  // ];

  const getAllCase = async () => {
    const res = await get('case/find'); 
    setData(res || []);
  }

  useEffect(()=>{
    getAllCase();
  },[]);
  

  const getValue = (row, accessor) => {
    if (typeof accessor === "function") {
      return accessor(row);
    }
    return row[accessor] || "";
  };

  // const [data, setData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [role, setRole] = useState([]);
  const [files, setFiles] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getUsers = async () => {
    const data = await get(`user/find`);
    setRole(data);
  }

  const getKPI = async () => {
    const data = await get(`kpi/find`);
    // setData(data || []);
  }

  const getProject = async () => {
    const data = await get(`project/find`);
    setProjectData(data);
  }

  // useEffect(() => {
  //   getKPI();
  //   getProject();
  //   getUsers();
  // }, [])

  const [Name, setName] = useState("");

  const [Department, setDepartment] = useState("");
  const [IndicatorType, setIndicatorType] = useState([]);

  const handleIndicator = (data) => {
    let arr = [...IndicatorType];
    arr.push({Name: data});
    setIndicatorType(arr);
    setType("");
  }

  const removeIndicator = (index) => {
    let arr = IndicatorType.filter((x, i) => i != index);
    setIndicatorType(arr);
  }

  const [ProjectID, setProjectID] = useState("")
  const [Type, setType] = useState("")
  const [Amount, setAmount] = useState(0)
  const [Description, setDescription] = useState("")
  const [VendorID, setVendorID] = useState()
  const [FoID, setFoID] = useState()
  const [PsID, setPsID] = useState()
  const [EoID, setEoID] = useState()
  const [HodID, setHodID] = useState()

  const columns = useMemo(
    () => [
      {
        name: 'Case ID',
        selector: (row) => `C-${row?.CaseID}`,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Case Type',
        selector: (row) => row?.CaseType,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Created Date',
        selector: (row) => formatDateTime(row?.createdDate),
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Case Status',
        selector: (row) => row?.CaseStatus,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Complainant Name',
        selector: row => row?.complainant?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Accused Name',
        selector: row => row?.accused?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Assigned Officer',
        selector: (row) => row?.officer?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            color="primary"
            size="sm"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const paginatedData = data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const navigate = useNavigate();

  const handleViewDetails = (rowData) => {
    navigate(`/case-detail/${rowData.CaseID}`);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const [KPIModal, setKPIModal] = useState(false);

  const addKPIDefinition = async () => {
    const data = {
      Name,
      Department,
      ProjectID,
      HodID,
      IndicatorType
    }
    const res = await post(`kpi/create`, data);
    setKPIModal(false);
    getKPI();
  }

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const add_PaymentVoucher = () => {
    setKPIModal(!KPIModal);
    removeBodyCss();
  };

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
  }, []);

  const filteredData = searchText.trim() === ""
    ? data // If searchText is empty, return all data without filtering
    : data.filter(
      (item) =>
        item?.CaseType?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.CaseStatus?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.CaseID?.toString().includes(searchText) ||
        item?.ComplainantName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.AccusedName?.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
        (item?.officer || "").toLowerCase().includes(searchText.toLowerCase())
    );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData); // Convert data to sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append the sheet to the workbook
    XLSX.writeFile(wb, 'payment_voucher_data.xlsx'); // Export as .xlsx file
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <h5 style={{paddingTop: '20px'}}>Case Management</h5>
        <p>Case Management</p>
        <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
          <Row>
            <Col sm={6}><h5>Case Management<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
            </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>

            </Col>
            <Col sm={6} hidden={userRole != 'Police Officer'} className="text-end"><Button color="primary" onClick={()=> navigate('/create-case')}> <i className="fa fa-plus"></i> Create </Button></Col>

          </Row>

          <Row className="mt-4">
            <Col sm={6}>
              <div className="table-subheader">
                <span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </span>
              </div>
            </Col>
            <Col sm={6}>
              <button className="btn btn-success" style={{float: "right"}} onClick={handleExport}>Export</button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                highlightOnHover
                striped
                responsive
              />
            </Col>
          </Row>
          <Row>

            <Pagination style={{ float: "right" }}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={handlePrevPage} />
              </PaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={currentPage == index + 1} key={index}>
                  <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
            </Pagination>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseManagement;
