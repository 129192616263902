import { del, get, post, postForm } from "helpers/api_helper";
import { getLoggedInUser } from "helpers/fakebackend_helper";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Alert,
    ModalHeader
} from "reactstrap";
import toastr from "toastr";

const AddYearlyExpense = () => {
    const navigate = useNavigate();
    document.title = "Add Yearly Expense";

    const [Agency, setAgency] = useState('');
    const [ApprovalUserID, setApprovalUserID] = useState();
    const [TotalExpense, setTotalExpense] = useState(0);
    const [BudgetYear, setBudgetYear] = useState();

    const [headCosts, setHeadCosts] = useState([]);

    const [costError, setCostError] = useState("");

    const [modal, setModal] = useState(false);

    // const headCostList = [
    //     "MoHAIT - 2300123234",
    //     "MoHAFIN - 23001236578",
    //     "MoHACON - 2300123455",
    //     "MoHAMAR - 2300124356"
    // ]

    const [headCostList, setHeadCostList] = useState([]);

    const [headCostType, setHeadCostType] = useState('');
    const [headCostCode, setHeadCostCode] = useState('');
    const [headCostDescription, setHeadCostDescription] = useState("");

    const [headCostName, setHeadCostName] = useState();
    const [headCostAmount, setHeadCostAmount] = useState();

    const [confirmModal, setConfirmModal] = useState(false);

    const [files, setFiles] = useState([]);

    const [type, setType] = useState("");

    useEffect(()=>{
        setTimeout(()=>{
            setCostError("");
        },3000);
    },[costError]);

    const startyear = 2024
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < (currentYear - 2015); i++) {
        years.push(`July ${startyear + i} to June ${startyear + i + 1}`);
    }

    const [psList, setPsList] = useState([]);

    const handleHeadCostModal = () => {
        setModal(!modal);
    }

    const getPS = async () => {
        const res = await get('user/find-by-role-name/Permanent Secretary');
        setPsList(res);
        setApprovalUserID(res && res.length > 0 ? res[0].UserID : 0);
    }

    const getHeadCostType = async () => {
        const res = await get('yearlyExpense/headcost-type/find');
        setHeadCostList(res);
    }

    useEffect(()=>{
        getHeadCostType();
        getPS();
    },[]);

    const deleteHeadCostType = async (id) => {
        await del(`yearlyExpense/headcost-type/delete/${id}`);
        getHeadCostType();
    }

    const addHeadCostType = async () => {
        const data = {
            Name : headCostType,
            Code : headCostCode,
            Description: headCostDescription
        };

        await post('yearlyExpense/headcost-type/create', data);
        getHeadCostType();
    }

    const handleSubmit = async () => {
        if(!BudgetYear || !TotalExpense){
            toastr.error("All fields are required", "Error");
            return;
        }
        const user = getLoggedInUser();
        const data = new FormData();
        data.append('BudgetYear',BudgetYear);
        data.append('Agency',Agency);
        data.append('TotalExpense',TotalExpense);
        data.append('ApprovalUserID',ApprovalUserID);
        data.append('ApprovalStatus','Pending');
        data.append('CreatedBy',user.id);
        data.append('headCosts',JSON.stringify(headCosts));

        for (let i = 0; i < files.length; i++) {
            data.append('mediaFiles', files[i]);
        }

        await postForm('yearlyExpense/create', data);
        navigate('/yearly-expense');
    }

    const handleHeadCost = () => {
        if(!headCostName || !headCostAmount || !type) {
            toastr.error("All fields are required", "Error");
            return;
        };
        if(headCostAmount == 0){
            toastr.error("Amount cannot be 0");
            return;
        }
        if(headCosts.some(obj => obj.Name == headCostName)){
            toastr.error(`Head Cost "${headCostName}" is already in the list`,"Error");
            return;
        }
        let totalHeadCost = headCosts.reduce((total, acc)=> parseFloat(total) + parseFloat(acc.Amount), 0);
        console.log(totalHeadCost);
        let tempCost = parseFloat(totalHeadCost) + parseFloat(headCostAmount);
        if(tempCost > parseFloat(TotalExpense)){
            setCostError(`Expense cannot exceed ${TotalExpense}`);
            return 
        }
        
        const temp = headCostList.find(x=> x.Name == headCostName);

        const data = {
            Name : headCostName,
            Code: temp?.Code,
            Description: temp?.Description,
            Type: type,

            Amount : parseFloat(headCostAmount)
        }

        setHeadCosts(prevHeadCosts => [...prevHeadCosts, data]);
    }

    

    const [tempId, setTempId] = useState();

    const openConfirmModal = (i) => {
        setTempId(i);
        setConfirmModal(true);
    }

    const handleRemoveHeadCostByIndex = (indexToRemove) => {
        setHeadCosts(prevHeadCosts => prevHeadCosts.filter((_, index) => index !== indexToRemove));
        setTempId(null);
        setConfirmModal(false);
    };

    const closeConfirmModal = () => {
        setTempId(null);
        setConfirmModal(false);
    }

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container className="mt-4">
                    <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                        {/* <h5>Single Yearly Expense</h5> */}

                        <Row className="mb-4 mt-4">
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="selectAgency" className="me-4">Budget Year</Label>
                                    <Input type="select"
                                        placeholder="Enter Type of ID"
                                        id="selectAgency"
                                        onChange={(e)=>setBudgetYear(e.target.value)}
                                        value={BudgetYear}
                                        required>
                                            <option selected>Select Year</option>
                                            {years.map(year => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            {/* <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="assignedPS" className="me-4">Assigned PS</Label>
                                    <Input type="select"
                                        placeholder="Assigned PS"
                                        id="assignedPS"
                                        onChange={(e)=>setApprovalUserID(e.target.value)}
                                        value={ApprovalUserID}
                                        required>
                                            <option selected>Select</option>
                                        {psList.map((ps)=>(
                                            <option value={ps.UserID}>{ps.Name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col> */}
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="totalExpense" className="me-4">Total Budget</Label>
                                    <Input type="text"
                                        placeholder="Total Budget"
                                        id="totalExpense"
                                        onChange={(e)=> setTotalExpense(e.target.value)}
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4 mt-4">
                            <h5 className="mb-4">Head Cost</h5>
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="headCost" className="me-4 mb-0">Select Head Cost</Label>
                                    <Input type="select"
                                        placeholder="Head Cost"
                                        id="headCost"
                                        onChange={(e)=> setHeadCostName(e.target.value)}
                                        value={headCostName}
                                        required>
                                            <option>Select</option>
                                            {headCostList.map((hostCost)=>(
                                                <option value={hostCost.Name}>{hostCost.Name}</option>
                                            ))}
                                    </Input>
                                    <button className="btn btn-primary mx-2" onClick={()=>setModal(true)}><i className="fa fa-cog"></i></button>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="type" className="me-4 mb-0">Type</Label>
                                    <Input type="select"
                                        placeholder="type"
                                        id="type"
                                        onChange={(e)=> setType(e.target.value)}
                                        value={type}
                                        required>
                                            <option selected disabled value={""}>Select</option>
                                            <option value={"Development"}>Development</option>
                                            <option value={"Non-Development"}>Non Development</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="amount" className="me-4 mb-0">Amount</Label>
                                    <Input type="number"
                                        placeholder="Amount"
                                        id="Amount"
                                        onChange={(e)=> setHeadCostAmount(e.target.value)}
                                        value={headCostAmount}
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <button className="btn btn-primary" onClick={handleHeadCost}>Add Headcost</button>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Modal isOpen={modal} toggle={handleHeadCostModal} size="lg">
                            <ModalBody style={{padding:"30px"}}>
                                <div className="text-end">
                                    <i style={{cursor: 'pointer'}} onClick={handleHeadCostModal} className="mdi mdi-close noti-icon" />
                                </div>
                                <h5 className="mb-4">Manage Head Cost Type</h5>
                                <Container>
                                    <Row>
                                        <Col sm={4}>
                                            <FormGroup className="d-flex align-items-center text-nowrap">
                                                <Label for="headCostName" className="me-4 mb-0">Name</Label>
                                                <Input type="text"
                                                    placeholder="Head Cost Type"
                                                    id="headCostName"
                                                    onChange={(e)=> setHeadCostType(e.target.value)}
                                                    value={headCostType}
                                                    >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup className="d-flex align-items-center text-nowrap">
                                                <Label for="headCostCode" className="me-4 mb-0">Code</Label>
                                                <Input type="text"
                                                    placeholder="Head Cost Type"
                                                    id="headCostCode"
                                                    onChange={(e)=> setHeadCostCode(e.target.value)}
                                                    value={headCostCode}
                                                    >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup className="d-flex align-items-center text-nowrap">
                                                <Label for="headCostDescription" className="me-4 mb-0">Description</Label>
                                                <Input type="text"
                                                    placeholder="Head Cost Type"
                                                    id="headCostDescription"
                                                    onChange={(e)=> setHeadCostDescription(e.target.value)}
                                                    value={headCostDescription}
                                                    >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12}>
                                                <button className="btn btn-primary mb-3" style={{float: 'right'}} onClick={addHeadCostType}>Add</button>
                                        </Col>
                                        <Col>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Code</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {headCostList?.map((headCostTypeRow) => (
                                                        <tr>
                                                            <td>{headCostTypeRow.ID}</td>
                                                            <td>{headCostTypeRow.Name}</td>
                                                            <td>{headCostTypeRow.Code}</td>
                                                            <td>{headCostTypeRow.Description}</td>
                                                            <td><button className="btn btn-danger" onClick={()=>deleteHeadCostType(headCostTypeRow.ID)}>Delete</button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalBody>
                        </Modal>
                        {costError &&
                            <Alert color="danger">{costError}</Alert>
                        }
                        <Modal isOpen={confirmModal} toggle={()=> setConfirmModal(!confirmModal)} size="md">
                            <ModalBody style={{padding:"30px"}}>
                                <h5>Delete Head Cost</h5>
                                <Container>
                                    <Row>
                                        <Col sm={12}>
                                            <p>Are you sure you want to delete this head cost?</p>
                                        </Col>
                                        <Col sm={12} className="text-end">
                                            <button className="btn btn-primary" onClick={()=> closeConfirmModal()}>No</button>
                                            <button className="btn btn-danger" onClick={()=>handleRemoveHeadCostByIndex(tempId)}>Yes</button>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalBody>
                        </Modal>
                        <Row>
                            <Col sm={6}>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>Head Cost</th>
                                            <th>Type</th>
                                            <th>Head Cost Code</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {headCosts.map((headCost,i)=>(
                                            <tr>
                                                <td>{headCost.Name}</td>
                                                <td>{headCost.Type}</td>
                                                <td>{headCost.Code}</td>
                                                <td>{headCost.Description}</td>
                                                <td>{headCost.Amount}</td>
                                                <td><button className="btn btn-danger" onClick={()=>openConfirmModal(i)}>Delete</button></td>
                                            </tr>
                                        ))}
                                        
                                        {(headCosts && headCosts.length > 0) &&
                                        <>
                                            <tr>
                                                <td><b>Utilized Budget</b></td>
                                                <td>{headCosts.reduce((total, acc) => total + parseFloat(acc.Amount), 0)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Remaining Budget</b></td>
                                                <td>{TotalExpense - headCosts.reduce((total, acc) => total + parseFloat(acc.Amount), 0)}</td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </Table></Col>
                        </Row>
                        {/* <Row>
                            <h5>Approvals</h5>
                            <Col sm={6}>
                                <Table bordered responsive>
                                    <tbody>
                                        <tr>
                                            <td>PS Approval</td>
                                            <td>Pending</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row> */}
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <label for="" class="me-5 form-label">Add Document</label>
                                <Input
                                type="file"
                                multiple
                                id=""
                                placeholder="Media"
                                className="h-25"
                                onChange={handleFileChange}
                                />
                            </FormGroup>
                        </Col>

                        <Row>                        
                            <Col sm={12}>
                                <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                    <button onClick={handleSubmit} className="btn btn-primary">Submit for Approval</button>
                                </FormGroup>    
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddYearlyExpense;
