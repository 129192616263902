import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup, Label, Input, Pagination, PaginationItem,PaginationLink, ModalHeader} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get, post, put } from "../../../../src/helpers/api_helper";
import moment from "moment";

import ReapproveVisitor from "../BuildingSecuritySetup/ReapproveVisitorForm";
import AddVisitor from "../BuildingSecuritySetup/AddVisitorForm";

const ViewVisitorDetails = () => {
    const { id } = useParams(); // Get the visitor ID from the URL
    const location = useLocation();
    const visitor = location.state?.visitor; // Retrieve visitor data passed via state

    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = () => setEditModal(!editModal);

    const [approveModal, setApproveModal] = useState(false);
    const toggleApproveModal = () => setApproveModal(!approveModal);

    const [visitorData, setVisitorData] = useState([]);
    const [visitorApprovalData, setVisitorApprovalData] = useState([]);
    const [visitorLogsData, setVisitorLogsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);

    const fetchVisitorData = async () => {
        try {
          let data = await get("visitors/find/" + id);
          setVisitorData(data);
          setVisitorApprovalData(data?.approvals);
          setVisitorLogsData(data.logs)
        } 
          catch (error) {
          console.error("Error fetching visitor data:", error);
        }
    };

    const handleFormSubmit = async (formData) => {
      try {
        let addCheckin = await post("approvals/create", formData);
        alert("Checked In successfully!");
        setApproveModal(false);
        fetchVisitorData();
      } 
      catch (error) {
        console.error("Error In Checkin:", error);
        alert("Failed to checkin Visitor.");
      }
  };




  const handleEditFormSubmit = async (formData) => {
    try {
      let updateVisitor = await put("visitors/update/" + formData.VisitorID, formData);
      alert("Visitor updated successfully!");
      setEditModal(false);
      fetchVisitorData();
    } 
    catch (error) {
      console.error("Error updating visitor:", error);
      alert("Failed to updating visitor.");
    }
  };



    useEffect(() => {
        fetchVisitorData();
    }, []);

    const getValue = (row, accessor) => {
        if (typeof accessor == "function") {
        return accessor(row);
        }
        return row[accessor] || "";
    };


    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = visitorApprovalData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visitorApprovalData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
  

  const columns = useMemo(
    () => [
        {
          header: "Check-In Time",
          accessor: (row) => row?.checkIn ? `${moment(row?.checkIn?.CheckinDate).format("DD/MM/YYYY") || ""} ${row?.checkIn?.CheckinTime || ""}` : "",
        },
        {
          header: "Check-Out Time",
          accessor: (row) => row?.checkIn?.checkOut? `${moment(row?.checkIn?.checkOut?.CheckoutDate).format("DD/MM/YYYY") || ""} ${row?.checkIn?.checkOut?.CheckoutTime || ""}` : "",
        },
        {
            header: "Check-In Officer",
            accessor: (row) => row?.checkIn ? `${row?.checkIn?.CheckinAuthority || ""}` : "",
        },
        {
            header: "Check-Out Officer",
            accessor: (row) => row?.checkIn?.checkOut? `${row?.checkIn?.checkOut?.CheckoutAuthority || ""}` : "",
        },
        {
            header: "Visitor Pass",
            accessor: (row) => row?.checkIn ? `${row?.checkIn?.VisitorPassNumber || ""}` : "",
        },
        {
          header: "Pass Return",
          accessor: (row) => row?.checkIn?.checkOut? `${row?.checkIn?.checkOut?.VisitorPassStatus == 1 ? "Returned" : "Not Returned" || ""}` : "",
        },
        { 
            header: "Visit Purpose", 
            accessor: "VisitPurpose" 
        },
        { 
            header: "Building Access", 
            accessor: "BuildingAccess" 
        },
        { 
          header: "Approval Authority", 
          accessor: "ApprovalAuthority" 
      },
      { 
          header: "Approval Type", 
          accessor: "ApprovalType" 
      },
    ],
    []
  );



  const logsColumns = useMemo(
    () => [
      { 
        header: "Visitor ID", 
        accessor: (row) => row?.VisitorID.toString().padStart(5, '0'),
      },
      {
        header: "Visitor Name",
        accessor: "VisitorName",
      },
      {
        header: "Visitor Address",
        accessor: "VisitorAddress",
      },
      {
        header: "ID Number",
        accessor: "ExternalID",
      },
      {
        header: "ID Type",
        accessor: "ExternalIDType",
      },
    ],
    []
  );



  document.title = "Visitor CheckIn | Building Security";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="align-items-center">
            <Col sm={6}>
              <Breadcrumbs title="Building Security" breadcrumbItem="Visitor Details" />
            </Col>
          </Row>

          {/* Table Section */}
          <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>

          <Row>
            <Col sm={6}>
              <h5 style={{ fontSize: "1rem" }}>Visitor Details</h5>
            </Col>
            <Col sm={6}>
                <Button  onClick={toggleEditModal} color="primary"  className="btn btn-primary waves-effect waves-light float-end">
                  <i className="fas fa-edit" style={{ marginRight: "10px" }}></i>
                  Edit Visitor
                </Button>

                <Button onClick={toggleApproveModal}  color="success"  className="btn btn-success waves-effect waves-light float-end me-2">
                  <i className="fa fa-plus" style={{ marginRight: "10px" }}></i>
                  Add Check-In
                </Button>
            </Col>
          </Row>



            <Row className="mt-4 mb-4">
              <Col sm={3}>
                  <strong>Visitor ID:</strong> {visitorData?.VisitorID?.toString().padStart(5, '0') || ""}
              </Col>

              <Col sm={3}>
                  <strong>Visitor Name:</strong> {visitorData?.VisitorName || ""}
              </Col>

              <Col sm={6}>
                  <strong>Visitor Address:</strong> {visitorData?.VisitorAddress || ""}
              </Col>

              <Col sm={3} className="mt-2">
                  <strong>ID Number:</strong> {visitorData?.ExternalID || ""}
              </Col>

              <Col sm={3} className="mt-2">
                  <strong>ID Type:</strong> {visitor?.ExternalIDType || ""}
              </Col>
            </Row>


            <h5 style={{ fontSize: "1rem" }}>Visitor Logs</h5>

            <Table bordered responsive style={{ fontSize: "0.8rem", tableLayout: "fixed", wordWrap: "break-word" }}>
              <thead>
                <tr>
                  {logsColumns.map((column, index) => (
                    <th key={index} style={{ fontSize: "0.8rem" }}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {visitorLogsData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {logsColumns.map((column, colIndex) => (
                      <td key={colIndex} style={{ fontSize: "0.8rem" }}>
                        {getValue(row, column.accessor)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <h5 style={{ fontSize: "1rem" }}>Check-In Details</h5>

            <Table bordered responsive style={{ fontSize: "0.8rem", tableLayout: "fixed", wordWrap: "break-word" }}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} style={{ fontSize: "0.8rem" }}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex} style={{ fontSize: "0.8rem" }}>
                        {getValue(row, column.accessor)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>


          
            <Modal isOpen={approveModal} toggle={toggleApproveModal} size="xl">
              <ModalHeader className="mt-0" toggle={toggleApproveModal}>Check-In Visitor</ModalHeader>
              <ModalBody style={{padding:"30px"}}>
                <ReapproveVisitor
                  toggle={toggleApproveModal}
                  onSubmit={handleFormSubmit}
                  visitorData={visitorData}
                />
              </ModalBody>
            </Modal>



            <Modal isOpen={editModal} toggle={toggleEditModal} size="lg">
              
              <ModalHeader className="mt-0" toggle={toggleEditModal}>{visitorData? "Edit Visitor" : "Add Visitor"}</ModalHeader>
              <ModalBody>
                <AddVisitor onSubmit={handleEditFormSubmit} toggle={toggleEditModal} visitorData={visitorData} />
              </ModalBody>
            </Modal>


            
            {/* Pagination Section */}
            <Pagination aria-label="Page navigation example" style={{float:"right"}}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem key={index} active={currentPage == index + 1}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
            </Pagination>
            </Row>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewVisitorDetails;
