import React, { useEffect, useMemo, useState } from "react";
import {Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup, Label, Input, Pagination, PaginationItem,PaginationLink} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CheckoutVisitorForm from "./BuildingSecuritySetup/CheckoutVisitorForm";
import { get, post } from "../../../src/helpers/api_helper";

const VisitorCheckOut = () => {
  let [visitorData, setVisitorData] = useState();
  const [checkoutData, setCheckoutData] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // You can adjust the items per page as needed

  const [VisitorName, setVisitorName] = useState("");
  const [VisitorIDNumber, setVisitorIDNumber] = useState("");

  const toggleModal = () => setModal(!modal);

  const handleFormSubmit = async (formData) => {
    try {
      await post("checkout/create", formData);
      alert("Checked Out Successfully!");
      setModal(false);
      fetchCheckout();
    } catch (error) {
      console.error("Error checking out visitor:", error);
      alert("Failed to check out visitor.");
    }
  };

  const clickCheckOut = (row) => {
    setVisitorData(row);
    setModal(!modal);
  };

  const fetchCheckout = async () => {
    try {
      const data = await get("checkIn/find");
      setCheckoutData(data);
    } catch (error) {
      console.error("Error fetching checkin data:", error);
    }
  };



  const searchVisitor = async () => {
    try {
      let data = await get("checkIn/find");

      if (VisitorName && VisitorName.length > 0) {
        data = data.filter(item => item.visitor.VisitorName.toLowerCase().includes(VisitorName.toLowerCase()));
      }

      if (VisitorIDNumber && VisitorIDNumber.length > 0) {
        data = data.filter(item => item.visitor.ExternalID.toString().includes(VisitorIDNumber.toString()));
      }

      setCheckoutData(data);
    } 
    catch (error) {
      console.error("Error fetching visitor data:", error);
    }
  };



  useEffect(() => {
    fetchCheckout();
  }, []);


  const getValue = (row, accessor) => {
    if (typeof accessor == "function") {
      return accessor(row); // Call the accessor if it's a function
    }
    const keys = accessor.split(".");
    return keys.reduce((value, key) => (value && value[key] ? value[key] : ""), row);
  };


  const columns = useMemo(
    () => [
      {
        header: "Check-Out Time",
        accessor: (row) => row.checkOut ? `${row.checkOut.CheckoutDate || ""} ${row.checkOut.CheckoutTime || ""}` : "",
      },
      { header: "Visitor ID", accessor: "VisitorID" },
      {
        header: "Visitor Name",
        accessor: (row) => row.visitor ? `${row.visitor.VisitorName || ""}` : "",
      },
      { header: "Visitor Pass", accessor: "VisitorPass" },
      {
        header: "Visitor Pass Return",
        accessor: (row) => row.checkOut ? `${row.checkOut.VisitorPassStatus == 1 ? "Returned" : "Not Returned"}` : "",
      },
      {
        header: "Check-Out Officer",
        accessor: (row) => row.checkOut ? `${row.checkOut.CheckoutAuthority || ""}` : "",
      },
      // {
      //   header: "Check-Out Officer Rank",
      //   accessor: (row) => row.checkOut ? `${row.checkOut.CheckoutOfficerRank || ""}` : "",
      // },
      { header: "Building Access", accessor: "BuildingAccess" },
      { header: "Actions", accessor: "actions" },
    ],
    []
  );

  // Get the current page's data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = checkoutData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(checkoutData.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  document.title = "Visitor CheckOut | Building Security";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="align-items-center">
            <Col sm={6}>
              <Breadcrumbs title="Building Security" breadcrumbItem="Visitor Check-Out" />
            </Col>

            {/* <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Button color="primary" onClick={toggleModal} className="btn btn-primary waves-effect waves-light">
                  <i className="fa fa-plus" style={{ marginRight: "10px" }}></i>
                  Create
                </Button>
              </div>
            </Col> */}
          </Row>

          {/* Modal */}
          <Modal isOpen={modal} toggle={toggleModal} size="xl">
            <ModalBody>
              <CheckoutVisitorForm toggle={clickCheckOut} visitorData={visitorData} onSubmit={handleFormSubmit} />
            </ModalBody>
          </Modal>

          {/* Table Section */}
          <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
            <h5 style={{ fontSize: "1rem" }}>Check-Out</h5>


            <Row className="mb-4 mt-4">
              <Col sm={3}>
                <FormGroup>
                  <Label for="VisitorName">Visitor Name</Label>
                  <Input
                    type="text"
                    id="VisitorName"
                    placeholder="Enter Visitor Name"
                    value={VisitorName}
                    onChange={(e) => setVisitorName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="VisitorIDNumber">ID Number</Label>
                  <Input
                    type="text"
                    id="VisitorIDNumber"
                    placeholder="Enter ID Number"
                    value={VisitorIDNumber}
                    onChange={(e) => setVisitorIDNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button color="primary" style={{ marginTop: "30px" }} onClick={searchVisitor}>
                  Search
                </Button>
              </Col>
            </Row>


            <Table bordered responsive style={{ fontSize: "0.8rem", tableLayout: "fixed", wordWrap: "break-word", }}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} style={{ fontSize: "0.9rem" }}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex} style={{ fontSize: "0.8rem" }}>
                        {column.accessor == "actions" ? (
                          <div>
                            <button disabled={row.checkOut} className="btn btn-danger btn-sm" onClick={() => clickCheckOut(row)} style={{ marginRight: "5px" }}>
                            {row.checkOut? 'Checked Out' : 'Check Out'}
                            </button>
                          </div>
                        ) : (
                          getValue(row, column.accessor)
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>
            {/* Pagination */}
            <Pagination style={{float:"right"}}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>

              {[...Array(totalPages).keys()].map((number) => (
                <PaginationItem key={number} active={number + 1 == currentPage}>
                  <PaginationLink onClick={() => handlePageChange(number + 1)}>
                    {number + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
            </Pagination>
            </Row>

          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VisitorCheckOut;
