import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Tooltip,
    ModalHeader
} from "reactstrap";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, postForm, put, putForm } from "helpers/api_helper";
import moment from 'moment';

import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";

import './project.css';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const CaseDetails = () => {
    document.title = "Case Detail";
    const navigate = useNavigate();
    
    const caseRef = useRef(null);

    const { id } = useParams();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const [CaseType, setCaseType] = useState("");
    const [OfficerID, setOfficerID] = useState("");
    const [CaseStatus, setCaseStatus] = useState("");
    const [Description, setDescription] = useState("");
    const [Department, setDepartment] = useState("");

    const [complainantName, setComplainantName] = useState("");
    const [complainantContact, setComplainantContact] = useState("");
    const [complainantAddress, setComplainantAddress] = useState("");
    const [complainantIDNumber, setComplainantIDNumber] = useState("");
    const [complainantDOB, setComplainantDOB] = useState("");
    const [complainantGender, setComplainantGender] = useState("");
    const [complainantOtherDetails, setComplainantOtherDetails] = useState("");
    const [complainantRelation, setComplainantRelation] = useState("");

    const [accusedName, setAccusedName] = useState("");
    const [accusedContact, setAccusedContact] = useState("");
    const [accusedAddress, setAccusedAddress] = useState("");
    const [accusedIDNumber, setAccusedIDNumber] = useState("");
    const [accusedDOB, setAccusedDOB] = useState("");
    const [accusedGender, setAccusedGender] = useState("");
    const [accusedOtherDetails, setAccusedOtherDetails] = useState("");
    const [accusedRelation, setAccusedRelation] = useState("");

    const [evidenceType, setEvidenceType] = useState("");
    const [evidenceCollectedBy, setEvidenceCollectedBy] = useState("");

    const [witnessName, setWitnessName] = useState("");
    const [witnessContact, setWitnessContact] = useState("");
    const [witnessAddress, setWitnessAddress] = useState("");
    const [witnessIDType, setWitnessIDType] = useState("");
    const [witnessIDNumber, setWitnessIDNumber] = useState("");

    const [EvidenceModal, setEvidenceModal] = useState(false);
    const [EvidenceList, setEvidenceList] = useState([]);

    const [WitnessModal, setWitnessModal] = useState(false);
    const [WitnessList, setWitnessList] = useState([]);

    const [TaskLogList, setTaskLogList] = useState([]);

    const [forwardToUser, setForwardToUser] = useState([]);
    const [forwardTo, setForwardTo] = useState(0);
    const [ActionTaken, setActionTaken] = useState("");
    const [statusChange, setStatusChange] = useState("");

    const [media, setMedia] = useState([]);
    const [courtMedia, setCourtMedia] = useState([]);

    const getCaseByID = async () => {
      const response = await get(`case/find/${id}`);
      if(response){
        setCaseType(response?.CaseType);
        setOfficerID(response?.OfficerID);
        setCaseStatus(response?.CaseStatus);
        setDescription(response?.Description);
        setDepartment(response?.Department);

        setComplainantName(response?.complainantName);
        setComplainantContact(response?.complainant.Contact);
        setComplainantAddress(response?.complainant.Address);
        setComplainantIDNumber(response?.complainant.IDNumber);
        setComplainantDOB(response?.complainant.DOB);
        setComplainantGender(response?.complainant.Gender);
        setComplainantOtherDetails(response?.complainant.OtherDetails);
        setComplainantRelation(response?.complainant.Relation);

        setAccusedName(response?.accused?.Name);
        setAccusedContact(response?.accused?.Contact);
        setAccusedAddress(response?.accused?.Address);
        setAccusedIDNumber(response?.accused?.IDNumber);
        setAccusedDOB(response?.accused?.DOB);
        setAccusedGender(response?.accused?.Gender);
        setAccusedOtherDetails(response?.accused?.OtherDetails);
        setAccusedRelation(response?.accused?.Relation);

        setEvidenceList(response?.evidences);

        setWitnessList(response?.witnesses);

        setTaskLogList(response?.caseLogs);

        setMedia(response?.media);
        setCourtMedia(response?.caseMedia);
      }
    }

    const [users, setUsers] = useState([]);
    
    const getUsers = async () => {
      const u = getLoggedInUserRole();
      const data = await get(`user/find`);
      setUsers(data);

      if(u == 'Police Officer'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Case Officer' || x.role.Name == 'Legal Team'));
      }
      else if(u == 'Case Officer'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Investigation Officer'));
      }
      else if(u == 'Investigation Officer'){
        setForwardToUser(data.filter(x=> x.role.Name == 'SIO' || x.role.Name == 'Case Officer'));
      }
      else if(u == 'SIO'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Station Sergeant' || x.role.Name == 'Investigation Officer' || x.role.Name == 'Police Officer'));
      }
      else if(u == 'Station Sergeant'){
        setForwardToUser(data.filter(x=> x.role.Name == 'SIO' || x.role.Name == 'SubDivisional Officer'));
      }
      else if(u == 'SubDivisional Officer'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Commander of Division'));
      }
      else if(u == 'Commander of Division'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Division Headquarter'));
      }
      else if(u == 'Division Headquarter'){
        setForwardToUser(data.filter(x=> x.role.Name == 'DPP' || x.role.Name == 'PCA'));
      }
      else if(u == 'DPP' || u == 'PCA'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Police Officer'));
      }
      else if(u == 'Legal Team'){
        setForwardToUser(data.filter(x=> x.role.Name == 'Police Officer'));
      }
      else{
        setForwardToUser(data);
      }
    }

    useEffect(()=>{
      getUsers();
      getCaseByID();
    },[id]);

    const updateCase = async () => {
      const data = {
        case: {
          CaseType,
          OfficerID: forwardTo || OfficerID,
          CaseStatus: statusChange || CaseStatus,
          ActionTaken,
          Description,
          Department
        },
        complainant: {
          Name: complainantName,
          Contact: complainantContact,
          Address: complainantAddress,
          IDNumber: complainantIDNumber,
          DOB: complainantDOB,
          Gender: complainantGender,
          OtherDetails: complainantOtherDetails,
          Relation: complainantRelation
        },
        accused: {
          Name: accusedName,
          Contact: accusedContact,
          Address: accusedAddress,
          IDNumber: accusedIDNumber,
          DOB: accusedDOB,
          Gender: accusedGender,
          OtherDetails: accusedOtherDetails,
          Relation: accusedRelation
        }
      }

      await put(`case/update/${id}`, data);
      navigate('/case-management');
    }


    const createEvidence = async () => {
      const data = {
        Type: evidenceType,
        CollectedBy: evidenceCollectedBy,
        CaseID: id,
      }

      await post(`case/create-evidence`, data);

      setEvidenceType("");
      setEvidenceCollectedBy("");
      setEvidenceModal(false);
      getCaseByID();
    }

    const createWitness = async () => {
      const data = {
        Name: witnessName,
        Contact: witnessContact,
        Address: witnessAddress,
        IDType: witnessIDType,
        IDNumber: witnessIDNumber,
        CaseID: id,
      }

      await post(`case/create-witness`, data);

      setWitnessName("");
      setWitnessContact("");
      setWitnessAddress("");
      setWitnessIDType("");
      setWitnessIDNumber("");
      setWitnessModal(false);
      getCaseByID();
    }

    const caseTypes = [
      "Theft",
      "Assault",
      "Fraud",
      "Burglary",
      "Arson",
      "Kidnapping",
      "Homicide",
      "Drug Trafficking",
      "Vandalism",
      "Robbery"
    ];
    
    const caseStatuses = [
      'Awaiting Case Verification',
      'Case Resolved - Release',
      'Initial Investigation',
      'Require Further Information',
      'Under Review by Station Sergeant',
      'Under Review by Sub-Divisional Officer',
      'Under Review by Commander of Division',
      'Under Review by Division Headquarter',
      'Under Review by DPP / PCA',
      'Case Approved - Charged',
      'Assigned to Legal',
      'Case Resolved - Convicted',
      'Case Closed on Court Order'
    ];
    
    const caseDepartments = [
      "Criminal Investigation",
      "Violent Crimes",
      "Cyber Crimes",
      "Property Crimes",
      "Fire & Safety",
      "Special Task Force",
      "Homicide Unit",
      "Narcotics",
      "Community Policing",
      "Rapid Response Unit"
    ];    
    
    const officers = [
      "Professor",
      "Officer Murphy",
      "Detective Harper",
      "Detective Holmes",
      "Sergeant Ross",
      "Agent Carter",
      "Detective Morgan",
      "Agent Johnson",
      "Officer Thompson",
      "Captain Reynolds"
    ];    

    const [files, setFiles] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);

    const handleFileChange = (e) => {
      setFiles(Array.from(e.target.files));
    };

    const removeFile = (index) => {
      let arr = files.filter((x, i) => i != index);
      setFiles(arr);

      const dataTransfer = new DataTransfer();
      arr.forEach((file) => dataTransfer.items.add(file));
      document.getElementById('uploadDocuments').files = dataTransfer.files;
    }

    const handleRemoveMedia = (file) => {
      setDeletedFiles([...deletedFiles, file]);
      setMedia(media.filter(x => x.FilePath != file));
    }

    const handleMediaUpload = async () => {
      const data = new FormData();
      data.append('CaseID', id);
      for (let i = 0; i < files.length; i++) {
        data.append('mediaFiles', files[i]);
      }

      await postForm(`case/upload-media`, data);
      getCaseByID();
      setFiles([]);
    }

    const handleRemoveMediaUploads = async () => {
      const data = new FormData();
      data.append('CaseID', id);
      data.append('deletedFiles', JSON.stringify(deletedFiles));
      await postForm(`case/upload-media`, data);
      getCaseByID();
      setDeletedFiles([]);
    }

    const [courtFiles, setCourtFiles] = useState([]);
    const [deletedCourtFiles, setDeletedCourtFiles] = useState([]);

    const handleCourtFileChange = (e) => {
      setCourtFiles(Array.from(e.target.files));
    };

    const removeCourtFile = (index) => {
      let arr = courtFiles.filter((x, i) => i != index);
      setCourtFiles(arr);

      const dataTransfer = new DataTransfer();
      arr.forEach((file) => dataTransfer.items.add(file));
      document.getElementById('uploadCourtDocuments').files = dataTransfer.files;
    }

    const handleRemoveCourtMedia = (file) => {
      setDeletedCourtFiles([...deletedCourtFiles, file]);
      setCourtMedia(courtMedia.filter(x => x.FilePath != file));
    }

    const handleCourtMediaUpload = async () => {
      const data = new FormData();
      data.append('CaseID', id);
      for (let i = 0; i < courtFiles.length; i++) {
        data.append('mediaFiles', courtFiles[i]);
      }

      await postForm(`case/upload-court-media`, data);
      getCaseByID();
      setCourtFiles([]);
    }

    const handleRemoveCourtMediaUploads = async () => {
      const data = new FormData();
      data.append('CaseID', id);
      data.append('deletedFiles', JSON.stringify(deletedCourtFiles));
      await postForm(`case/upload-court-media`, data);
      getCaseByID();
      setDeletedCourtFiles([]);
    }

    const [userRole, setUserRole] = useState('');
    
    useEffect(() => {
      setUserRole(getLoggedInUserRole());
    }, []);

    const downloadChart = () => {
      if (caseRef.current) {
        toPng(caseRef.current, { backgroundColor: 'white' })
          .then((dataUrl) => {
          //   const link = document.createElement('a');
          //   link.download = 'chart.png';
          //   link.href = dataUrl;
          //   link.click();
              const pdf = new jsPDF("p", "mm", "a4");
      
              const pdfWidth = pdf.internal.pageSize.getWidth()-10;

              let dimenstion = caseRef.current.getBoundingClientRect();
              const pdfHeight = (dimenstion?.height * pdfWidth)/ dimenstion?.width;

              pdf.addImage(dataUrl, "PNG", 5, 0, pdfWidth, pdfHeight);
              pdf.save(`case-${id}.pdf`);
          })
          .catch((err) => {
            console.error('Failed to download chart:', err);
          });
      }
    };

    return (
        <React.Fragment>

            <div className="page-content">
                <h5 style={{paddingTop: '20px'}}>Case Management</h5>
                <p>Case Management <b>&gt;</b> Case Details</p>
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <Row>
                      <Col sm={6}><h5>Case Details<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
                      </h5>
          
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen}
                          target="pageHeading"
                          toggle={toggleTooltip}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
                        </Tooltip>
          
                      </Col>
                      <Col sm={6} hidden={userRole != 'Police Officer'} className="text-end"><Button color="primary" onClick={downloadChart}> <i className="fa fa-plus"></i> Generate </Button></Col>
          
                    </Row>

                  <div ref={caseRef}>
                    <Row className="mb-4 mt-4">
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Case Type</Label>
                              <Input
                                  type="select"
                                  id=""
                                  disabled
                                  // onChange={(e)=> setCaseType(e.target.value)}
                                  value={CaseType}
                                  placeholder="Select"
                              >
                                <option>Select</option>
                                {caseTypes.map(type=>(
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Select Officer</Label>
                              <Input
                                  type="select"
                                  id=""
                                  placeholder="Select"
                                  disabled
                                  // onChange={(e)=> setOfficerID(e.target.value)}
                                  value={OfficerID}
                              >
                                <option>Select</option>
                                {users.map(user=>(
                                  <option key={user.UserID} value={user.UserID}>{user.Name}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Case Status</Label>
                              <Input
                                  type="select"
                                  id=""
                                  placeholder="Select"
                                  disabled
                                  // onChange={(e)=> setCaseStatus(e.target.value)}
                                  value={CaseStatus}
                              >
                                <option>Select</option>
                                {caseStatuses.map(type=>(
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Case Description</Label>
                              <textarea
                                  type="text"
                                  id=""
                                  disabled
                                  // onChange={(e)=> setDescription(e.target.value)}
                                  value={Description}
                                  rows={4}
                                  cols={200}
                                  placeholder="Type here..."
                                  style={{borderColor: '#e9ecef'}}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Department</Label>
                              <Input
                                  type="select"
                                  id=""
                                  placeholder="Select"
                                  disabled
                                  // onChange={(e)=> setDepartment(e.target.value)}
                                  value={Department}
                              >
                                <option>Select</option>
                                {caseDepartments.map(type=>(
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-4 mt-4">
                        <Col sm={12}>
                          <h4>Complainant Details</h4>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Complainant Name</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantName(e.target.value)}
                                  value={complainantName}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Complainant Contact</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantContact(e.target.value)}
                                  value={complainantContact}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Complainant Address</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantAddress(e.target.value)}
                                  value={complainantAddress}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">ID Number</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantIDNumber(e.target.value)}
                                  value={complainantIDNumber}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">DOB</Label>
                              <Input
                                  type="date"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantDOB(e.target.value)}
                                  value={complainantDOB}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Gender</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantGender(e.target.value)}
                                  value={complainantGender}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Other Details</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantOtherDetails(e.target.value)}
                                  value={complainantOtherDetails}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Relation to Accused</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setComplainantRelation(e.target.value)}
                                  value={complainantRelation}
                              />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row className="mb-4 mt-4">
                        <Col sm={12}>
                          <h4>Accused Details</h4>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Accused Name</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedName(e.target.value)}
                                  value={accusedName}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Accused Contact</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedContact(e.target.value)}
                                  value={accusedContact}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Accused Address</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedAddress(e.target.value)}
                                  value={accusedAddress}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">ID Number</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedIDNumber(e.target.value)}
                                  value={accusedIDNumber}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">DOB</Label>
                              <Input
                                  type="date"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedDOB(e.target.value)}
                                  value={accusedDOB}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Gender</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedGender(e.target.value)}
                                  value={accusedGender}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Other Details</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedOtherDetails(e.target.value)}
                                  value={accusedOtherDetails}
                              />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                              <Label for="" className="me-4 w-50">Relation to Complainant</Label>
                              <Input
                                  type="text"
                                  id=""
                                  placeholder="Type here"
                                  onChange={(e)=> setAccusedRelation(e.target.value)}
                                  value={accusedRelation}
                              />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                          <h4>Document</h4>
                        </Col>
                        <Col sm={11}>
                          <FormGroup className="d-flex align-items-center text-nowrap">
                            <Input
                                type="file"
                                multiple
                                id="uploadDocuments"
                                placeholder="Project Description"
                                className="h-25"
                                onChange={handleFileChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={1}>
                            <button className="btn btn-primary w-100" onClick={handleMediaUpload}>Upload</button>
                        </Col>

                        <Col sm={12}>
                          {files && files.length > 0 && <div style={{border: '1px solid #ececf1', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '10px', paddingTop: 0, marginBottom: '30px'}}>
                            {files?.map((file, index) => (
                              <Col sm={12} style={{backgroundColor: 'white', padding: '10px', border: '1px solid #ececf1', marginTop: '10px'}}>
                                <Row>
                                  <Col sm={8} style={{margin: 0}}>
                                    <h6>{file?.name}</h6>
                                  </Col>
                                  <Col sm={4} style={{margin: 0}}>
                                    <h6 className="fa-pull-right" onClick={()=> removeFile(index)}><i className="fa fa-times" style={{color: '#ec4561'}}></i></h6>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </div>}
                        </Col>

                        <Col sm={12}>
                          <ul>
                              <Row>
                                  {media.map((file) => (
                                      <Col sm={3}>
                                          <li className="media-item">
                                              <span className="media-name">{file.FileName}</span>
                                              <a href={`https://moha.sawarionline.com/${file.FilePath}`} download> <i className="fa fa-download"></i> </a>
                                              <button onClick={() => handleRemoveMedia(file.FilePath)} className="cross-button"><i className="fa fa-trash"></i></button>
                                          </li>
                                      </Col>
                                  ))}
                              </Row>
                          </ul>
                      </Col>
                      {deletedFiles.length > 0 && <Col sm={12}>
                          <div className="text-end mb-2">
                            <button className="btn btn-primary" onClick={handleRemoveMediaUploads}>Save Changes</button>
                          </div>
                      </Col>}
                      <hr/>
                    </Row>

                    <Row>
                        <Col sm={6}>
                          <h4>Evidence</h4>
                        </Col>
                        <Col sm={6}>
                          <div className="text-end">
                            <button className="btn btn-primary" onClick={()=>setEvidenceModal(true)}>Add Evidence</button>
                          </div>
                        </Col>

                        <Modal isOpen={EvidenceModal} toggle={()=>setEvidenceModal(!EvidenceModal)} size="lg">
                            <ModalHeader>Add Evidence</ModalHeader>
                            <ModalBody>
                              <Row>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Evidence Type</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Type"
                                      onChange={(e)=> setEvidenceType(e.target.value)}
                                      value={evidenceType}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Collected By</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Enter Name"
                                      onChange={(e)=> setEvidenceCollectedBy(e.target.value)}
                                      value={evidenceCollectedBy}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <div className="text-end">
                                    <button className="btn btn-primary" onClick={createEvidence}>Submit</button>
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                        </Modal>

                        <Col sm={12} className="mt-2">
                          <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th>Evidence ID</th>
                                    <th>Evidence Type</th>
                                    <th>Date</th>
                                    <th>Collected By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {EvidenceList?.length > 0 && EvidenceList?.map((evidence) => (
                                    <tr>
                                        <td>{evidence.EvidenceID}</td>
                                        <td>{evidence.Type}</td>
                                        <td>{formatDateTime(evidence.createdDate)}</td>
                                        <td>{evidence.CollectedBy}</td>
                                        <td><button className="btn btn-default">View</button></td>
                                    </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                          <h4>Witness</h4>
                        </Col>
                        <Col sm={6}>
                          <div className="text-end">
                            <button className="btn btn-primary" onClick={()=>setWitnessModal(true)}>Add Witness</button>
                          </div>
                        </Col>

                        <Modal isOpen={WitnessModal} toggle={()=>setWitnessModal(!WitnessModal)} size="lg">
                            <ModalHeader>Add Witness</ModalHeader>
                            <ModalBody>
                              <Row>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Name</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Enter Name"
                                      onChange={(e)=> setWitnessName(e.target.value)}
                                      value={witnessName}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Phone</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Enter Name"
                                      onChange={(e)=> setWitnessContact(e.target.value)}
                                      value={witnessContact}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Address</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Enter Name"
                                      onChange={(e)=> setWitnessAddress(e.target.value)}
                                      value={witnessAddress}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">ID Type</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Enter Name"
                                      onChange={(e)=> setWitnessIDType(e.target.value)}
                                      value={witnessIDType}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">ID Number</Label>
                                    <Input
                                      type="text"
                                      id=""
                                      placeholder="Enter Name"
                                      onChange={(e)=> setWitnessIDNumber(e.target.value)}
                                      value={witnessIDNumber}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <div className="text-end">
                                    <button className="btn btn-primary" onClick={createWitness}>Submit</button>
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                        </Modal>

                        <Col sm={12} className="mt-2">
                          <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th>Witness ID</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Address</th>
                                    <th>ID Type</th>
                                    <th>ID Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {WitnessList?.length > 0 && WitnessList?.map((witness) => (
                                    <tr>
                                        <td>{witness.WitnessID}</td>
                                        <td>{witness.Name}</td>
                                        <td>{witness.Contact}</td>
                                        <td>{witness.Address}</td>
                                        <td>{witness.IDType}</td>
                                        <td>{witness.IDNumber}</td>
                                        <td><button className="btn btn-default">View</button></td>
                                    </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                          <h4>Court Proceedings</h4>
                        </Col>
                        <Col sm={11} hidden={userRole != 'Legal Team'}>
                          <FormGroup className="d-flex align-items-center text-nowrap">
                            <Input
                                type="file"
                                multiple
                                id="uploadCourtDocuments"
                                placeholder="Project Description"
                                className="h-25"
                                onChange={handleCourtFileChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={1} hidden={userRole != 'Legal Team'}>
                            <button className="btn btn-primary w-100" onClick={handleCourtMediaUpload}>Upload</button>
                        </Col>

                        <Col sm={12}>
                          {courtFiles && courtFiles.length > 0 && <div style={{border: '1px solid #ececf1', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '10px', paddingTop: 0, marginBottom: '30px'}}>
                            {courtFiles?.map((file, index) => (
                              <Col sm={12} style={{backgroundColor: 'white', padding: '10px', border: '1px solid #ececf1', marginTop: '10px'}}>
                                <Row>
                                  <Col sm={8} style={{margin: 0}}>
                                    <h6>{file?.name}</h6>
                                  </Col>
                                  <Col sm={4} style={{margin: 0}}>
                                    <h6 className="fa-pull-right" onClick={()=> removeCourtFile(index)}><i className="fa fa-times" style={{color: '#ec4561'}}></i></h6>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </div>}
                        </Col>

                        <Col sm={12}>
                          <ul>
                              <Row>
                                  {courtMedia.map((file) => (
                                      <Col sm={3}>
                                          <li className="media-item">
                                              <span className="media-name">{file.FileName}</span>
                                              <a href={`https://moha.sawarionline.com/${file.FilePath}`} download> <i className="fa fa-download"></i> </a>
                                              <button onClick={() => handleRemoveCourtMedia(file.FilePath)} className="cross-button"><i className="fa fa-trash"></i></button>
                                          </li>
                                      </Col>
                                  ))}
                              </Row>
                          </ul>
                      </Col>
                      {deletedCourtFiles.length > 0 && <Col sm={12}>
                          <div className="text-end mb-2">
                            <button className="btn btn-primary" onClick={handleRemoveCourtMediaUploads}>Save Changes</button>
                          </div>
                      </Col>}
                      <hr/>
                    </Row>

                    <Row>
                        <Col sm={12}>
                          <h4>Task Logs</h4>
                        </Col>
                        <Col sm={12}>
                          <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th>Officer Name</th>
                                    <th>Officer Rank</th>
                                    <th>Department</th>
                                    <th>Date</th>
                                    <th>ForwardedTo</th>
                                    <th>Action Taken</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TaskLogList?.length > 0 && TaskLogList?.map((task) => (
                                    <tr>
                                        <td>{task?.officerLog?.Name}</td>
                                        <td>{task?.officerLog?.Designation}</td>
                                        <td>{task?.officerLog?.Department}</td>
                                        <td>{formatDateTime(task?.createdDate)}</td>
                                        <td>{task?.forwardedToLog?.Name}</td>
                                        <td>{task?.ActionTaken}</td>
                                        <td><button className="btn btn-default">View</button></td>
                                    </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <h4>Take Action</h4>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="d-flex align-items-center text-nowrap">
                          <Label for="" className="me-4 w-50">Change Status</Label>
                          <Input
                              type="select"
                              id=""
                              placeholder="Type here"
                              onChange={(e)=> setStatusChange(e.target.value)}
                              value={statusChange}
                          >
                            <option value={''}>Select Status</option>
                            {caseStatuses.map(type=>(
                              <option key={type} value={type}>{type}</option>
                            ))}
                            {/* <option value={'Awaiting Case Verification'}>Awaiting Case Verification</option>
                            <option value={'Case Resolved - Release'}>Case Resolved - Release</option>
                            <option value={'Initial Investigation'}>Initial Investigation</option>
                            <option value={'Require Further Information'}>Require Further Information</option>
                            <option value={'Under Review by Station Sergeant'}>Under Review by Station Sergeant</option>
                            <option value={'Under Review by Sub-Divisional Officer'}>Under Review by Sub-Divisional Officer</option>
                            <option value={'Under Review by Commander of Division'}>Under Review by Commander of Division</option>
                            <option value={'Under Review by Division Headquarter'}>Under Review by Division Headquarter</option>
                            <option value={'Under Review by DPP / PCA'}>Under Review by DPP / PCA</option>
                            <option value={'Case Approved - Charged'}>Case Approved - Charged</option>
                            <option value={'Assigned to Legal'}>Assigned to Legal</option>
                            <option value={'Case Resolved - Convicted'}>Case Resolved - Convicted</option>
                            <option value={'Case Closed on Court Order'}>Case Closed on Court Order</option> */}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="d-flex align-items-center text-nowrap">
                          <Label for="" className="me-4 w-50">Take Action</Label>
                          <Input
                              type="text"
                              id=""
                              placeholder="Type here"
                              onChange={(e)=> setActionTaken(e.target.value)}
                              value={ActionTaken}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup className="d-flex align-items-center text-nowrap">
                          <Label for="" className="me-4 w-50">Forwarded To</Label>
                          <Input
                              type="select"
                              id=""
                              placeholder="Type here"
                              onChange={(e)=> setForwardTo(e.target.value)}
                              value={forwardTo}
                          >
                            <option disabled selected value={0}>Select Officer</option>
                            {forwardToUser?.map(user => (
                              <option value={user?.UserID}>{user?.Name}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={8}>
                        <FormGroup className="d-flex align-items-center text-nowrap">
                          <Label for="" className="me-4 w-50">Case Description</Label>
                          <textarea
                              type="text"
                              id=""
                              rows={4}
                              cols={100}
                              placeholder="Type here"
                              style={{borderColor: '#e9ecef'}}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                    <Row>
                      <Col sm={12}>
                          <div className="text-end">
                            <button className="btn btn-primary" onClick={updateCase}>Submit</button>
                          </div>
                      </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CaseDetails;