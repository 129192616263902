import React, { useEffect, useMemo, useState } from "react";
import {Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup, Label, Input, Pagination, PaginationItem,PaginationLink, ModalHeader} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CheckoutVisitorForm from "./BuildingSecuritySetup/CheckoutVisitorForm";
import ReapproveVisitor from "./BuildingSecuritySetup/ReapproveVisitorForm";
import { get, post } from "../../../src/helpers/api_helper";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";

const VisitorLedger = () => {
    const [currentDate, setCurrentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [focusedInput, setFocusedInput] = useState(null);
    const [dates, setDates] = useState({
        startDate: moment(),
        endDate: moment(),
    });
    
    const [checkinData, setCheckinData] = useState([]);
    const [modal, setModal] = useState(false);
    let   [visitorData, setVisitorData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);

    const [VisitorName, setVisitorName] = useState("");
    const [VisitorIDNumber, setVisitorIDNumber] = useState("");
    const [VisitPurpose, setVisitPurpose] = useState("");
    const [BuildingAccess, setBuildingAccess] = useState("");  

    const [approveModal, setApproveModal] = useState(false);
    const toggleApproveModal = () => setApproveModal(!approveModal);

    const toggleModal = () => setModal(!modal);

    const handleFormSubmit = async (formData) => {
        try {
        let addCheckin = await post("approvals/create", formData);
        alert("Checked In successfully!");
        setApproveModal(false);
        fetchCheckin();
        } 
        catch (error) {
        console.error("Error In Checkin:", error);
        alert("Failed to checkin Visitor.");
        }
    };



    const handleCheckoutFormSubmit = async (formData) => {
        try {
            await post("checkout/create", formData);
            alert("Checked Out Successfully!");
            setModal(false);
            fetchCheckin();
        } 
        catch (error) {
            console.error("Error checking out visitor:", error);
            alert("Failed to check out visitor.");
        }
    };


    const clickCheckout = (row) => {
        setVisitorData(row);
        setModal(!modal);
    };

    const clickApprove = (row) => {
        // setVisitorData(row);
        toggleApproveModal();
    };

    const fetchCheckin = async () => {
        try {
        let startDate = moment(dates.startDate).format("YYYY-MM-DD");
        let endDate = moment(dates.endDate).format("YYYY-MM-DD");
        let searchUrl = "checkIn/find?startDate=" + startDate + "&endDate=" + endDate;

        let data = await get(searchUrl);
        setCheckinData(data);
        } 
        catch (error) {
        console.error("Error fetching checkin data:", error);
        }
    };

    useEffect(() => {
        fetchCheckin();
    }, []);

    const getValue = (row, accessor) => {
        if (typeof accessor == "function") {
        return accessor(row);
        }
        return row[accessor] || "";
    };

    const columns = useMemo(
    () => [
        { 
            header: "Visitor ID", 
            accessor: (row) => row.VisitorID.toString().padStart(5, '0'),
        },
        {
            header: "Visitor Name",
            accessor: (row) => row.visitor ? `${row.visitor.VisitorName || ""}` : "",
        },
        {
            header: "Check-In Time",
            accessor: (row) => row ? `${moment(row.CheckinDate).format("DD/MM/YYYY") || ""} ${row.CheckinTime || ""}` : "",
        },
        {
            header: "Check-Out Time",
            accessor: (row) => row.checkOut ? `${moment(row.checkOut.CheckoutDate).format("DD/MM/YYYY") || ""} ${row.checkOut.CheckoutTime || ""}` : "",
        },
        {
            header: "Check-In Officer",
            accessor: "CheckinAuthority",
        },
        {
            header: "Check-Out Officer",
            accessor: (row) => row.checkOut ? `${row.checkOut.CheckoutAuthority || ""}` : "",
        },
        {
            header: "Visitor Pass",
            accessor: "VisitorPassNumber",
        },
        {
            header: "Pass Return",
            accessor: (row) => row.checkOut ? `${row.checkOut.VisitorPassStatus == 1 ? "Returned" : "Not Returned" || ""}` : "",
        },
        { 
            header: "Building Access", 
            accessor: (row) => row.approvals ? `${row.approvals.BuildingAccess || ""}` : "",
        },
        { 
            header: "Visit Purpose", 
            accessor: (row) => row.approvals ? `${row.approvals.VisitPurpose || ""}` : "",
        },
        { 
            header: "Actions", 
            accessor: "actions" 
        },
    ],
    []
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = checkinData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(checkinData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const searchVisitor = async () => {
        try {

            let startDate = moment(dates.startDate).format("YYYY-MM-DD");
            let endDate = moment(dates.endDate).format("YYYY-MM-DD");
            let searchUrl = "checkIn/find?startDate=" + startDate + "&endDate=" + endDate;

            let data = await get(searchUrl);

            if (VisitorName && VisitorName.length > 0) {
                data = data.filter(item => item.visitor.VisitorName.toLowerCase().includes(VisitorName.toLowerCase()));
            }

            if (VisitorIDNumber && VisitorIDNumber.length > 0) {
                data = data.filter(item => item.visitor.ExternalID.toString().includes(VisitorIDNumber.toString()));
            }

            if (VisitPurpose && VisitPurpose.length > 0) {
                data = data.filter(item => item.approvals?.VisitPurpose?.toLowerCase().includes(VisitPurpose?.toLowerCase()));
            }
        
            if (BuildingAccess && BuildingAccess.length > 0) {
                data = data.filter(item => item.approvals?.BuildingAccess?.toLowerCase().includes(BuildingAccess?.toLowerCase()));
            }

            setCheckinData(data);
        } 
        catch (error) {
            console.error("Error fetching visitor data:", error);
        }
    };

  document.title = "Visitor Ledger | Building Security";

  return (
    <React.Fragment>

        <style>
                {`
                    .DateInput_input {
                        padding: 5px 11px 0px !important;
                        font-size: inherit !important; 
                    }
                `}
        </style>

      <div className="page-content">
        <Container fluid={true}>
          <Row className="align-items-center">
            <Col sm={6}>
              <Breadcrumbs title="Building Security" breadcrumbItem="Visitor Ledger" />
            </Col>

            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Button onClick={clickApprove}  color="primary"  className="btn btn-primary waves-effect waves-light">
                  <i className="fa fa-plus" style={{ marginRight: "10px" }}></i>
                  Add Check-In
                </Button>
              </div>
            </Col>
          </Row>

          {/* Modal */}
          <Modal isOpen={modal} toggle={toggleModal} size="xl">
            <ModalBody style={{padding:"30px"}}>
              <CheckoutVisitorForm toggle={toggleModal} visitorData={visitorData} onSubmit={handleCheckoutFormSubmit} />
            </ModalBody>
          </Modal>


          <Modal isOpen={approveModal} toggle={toggleApproveModal} size="xl">
          <ModalHeader className="mt-0" toggle={toggleApproveModal}>Check-In Visitor</ModalHeader>
            <ModalBody style={{padding:"30px"}}>
              <ReapproveVisitor
                toggle={toggleApproveModal}
                onSubmit={handleFormSubmit}
              />
            </ModalBody>
          </Modal>

          {/* Table Section */}
          <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
            <Row>
                <Col sm={6}>
                   <h5 style={{ fontSize: "1rem" }}>Check-In</h5>
                </Col>
                <Col sm={6}>
                   <h5 style={{ fontSize: "1rem", float: "right" }}>Current Date: {moment(currentDate).format("DD/MM/YYYY")}</h5>
                   {/* <h5 style={{ fontSize: "1rem", float: "right" }}>Selected Date Range: {moment(dates.startDate).format("YYYY-MM-DD") + "  -->  " + moment(dates.endDate).format("YYYY-MM-DD")}</h5> */}
                </Col>
            </Row>


            <Row className="mb-2 mt-2">
                <Col sm={12}>
                <FormGroup>
                    <Label for="VisitorName" className="me-2">Date Range: </Label>
                    <DateRangePicker className="date-range-picker"
                        startDate={dates.startDate}
                        startDateId="start_date_id"
                        endDate={dates.endDate}
                        endDateId="end_date_id"
                        onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })}
                        focusedInput={focusedInput}
                        onFocusChange={(input) => setFocusedInput(input)}
                        displayFormat="DD/MM/YYYY"
                        isOutsideRange={() => false}
                    />
                </FormGroup>
                </Col>
              <Col sm={2}>
                <FormGroup>
                  <Label for="VisitorName">Visitor Name</Label>
                  <Input
                    type="text"
                    id="VisitorName"
                    placeholder="Enter Visitor Name"
                    value={VisitorName}
                    onChange={(e) => setVisitorName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <Label for="VisitPurpose">Visit Purpose</Label>
                  <Input
                    type="text"
                    id="VisitPurpose"
                    placeholder="Enter Visit Purpose"
                    value={VisitPurpose}
                    onChange={(e) => setVisitPurpose(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <Label for="BuildingAccess">Building Access</Label>
                  <Input
                    type="text"
                    id="BuildingAccess"
                    placeholder="Enter Building Name"
                    value={BuildingAccess}
                    onChange={(e) => setBuildingAccess(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <Label for="VisitorIDNumber">ID Number</Label>
                  <Input
                    type="text"
                    id="VisitorIDNumber"
                    placeholder="Enter ID Number"
                    value={VisitorIDNumber}
                    onChange={(e) => setVisitorIDNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button color="primary" style={{ marginTop: "30px" }} onClick={searchVisitor}>
                  Search
                </Button>
              </Col>
            </Row>



            <Table bordered responsive style={{ fontSize: "0.8rem", tableLayout: "fixed", wordWrap: "break-word" }}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} style={{ fontSize: "0.8rem" }}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex} style={{ fontSize: "0.8rem" }}>
                        {column.accessor == "actions" ? (
                          <div>
                            <button disabled={row.checkOut} className="btn btn-danger btn-sm" onClick={() => clickCheckout(row)} style={{ marginRight: "5px" }}>
                                {row.checkOut? 'Checked Out' : 'Check Out'}
                            </button>
                          </div>
                        ) : (
                          getValue(row, column.accessor) // Call accessor function or retrieve value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>
            {/* Pagination Section */}
            <Pagination aria-label="Page navigation example" style={{float:"right"}}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem key={index} active={currentPage == index + 1}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
            </Pagination>
            </Row>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VisitorLedger;
