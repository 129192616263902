import React, { useEffect, useRef, useState } from 'react';
import { get, post, put } from "../../../helpers/api_helper";
import { Row, Col, Label, Input, Button, FormGroup, Form, Container, Tooltip } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";

const PublicVisitorForm = () => {
  const btnRef = useRef();
  const [ExternalID, setExternalID] = useState("");
  const [VisitorID, setVisitorID] = useState("");

  const [userRole, setUserRole] = useState("");

  const [VisitorPassNumber, setVisitorPassNumber] = useState("");

  const navigate = useNavigate();

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  
  const fullScreenFunction = () => {
    if (btnRef.current) {
      btnRef.current.click(); // Programmatically trigger the button click
    }
  }



  const [visitor, setVisitor] = useState({
    VisitorName: "",
    VisitorAddress: "",
    ExternalIDType: "Passport",
    ExternalID: ""
  });

  const [approvalFormData, setapprovalFormData] = useState({
    ApprovalType: "Manual",
    VisitPurpose: "Project Meeting",
  });

  const now = new Date();
  const currentDate = now.toISOString().split('T')[0];
  const currentTime = now.toTimeString().split(' ')[0];

  const [checkinFormData, setCheckinFormData] = useState({
    CheckinAuthority: "Help Desk Officer 1",
    CheckinDate: currentDate, // Explicit locale for mac support
    CheckinTime: currentTime, // Explicit locale for mac support
  });

  const resetForm = () => {
    setVisitor({
      VisitorName: "",
      VisitorAddress: "",
      ExternalIDType: "Passport",
      ExternalID: ""
    });
  
    setapprovalFormData({
      ApprovalType: "Manual",
      VisitPurpose: "Project Meeting",
      BuildingAccess: "",
      ApprovalAuthority: ""
    });
  
    setCheckinFormData({
      CheckinAuthority: "Help Desk Officer 1",
      CheckinDate: currentDate,
      CheckinTime: currentTime,
      VisitorPassNumber: ""
    });
  }

  const handleVisitorChange = (e) => {
    const { id, value } = e.target;
    setVisitor((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  }

  const handleApprovalChange = (e) => {
    const { id, value } = e.target;
    setapprovalFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCheckinChange = (e) => {
    const { id, value } = e.target;
    setCheckinFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const clickCheckin = async () => {
    console.log('CheckIn clicked!', { BuildingAccess, ApprovalAuthority, VisitPurpose });

    if (!formValidation()) {
      return;
    }

    let payload = {
      visitor,
      approvalDetails: approvalFormData,
      checkinDetails: checkinFormData
    }

    const response = await post('visitors/create-public-visitor', payload);
    if(response.message == 'Check in success!'){
      toastr.success(response.message, 'Success');
      resetForm();
    }
  };

  const checkOut = async () => {
    let res;
    if(VisitorPassNumber){
      res = await post('visitors/checkout-public-visitor', {VisitorPassNumber});
    }
    if(res && res?.VisitorPassStatus == '1'){
      toastr.success("Check out success!", 'Success');
      setVisitorPassNumber("");
    }
  }

  const findVisitor = async () => {
    try {
      let data = await get("visitors/find");

      if (ExternalID && ExternalID.length > 0) {
        data = data.filter(item => item.ExternalID == ExternalID);
      }

      if (VisitorID && VisitorID.length > 0) {
        data = data.filter(item => item.VisitorID.toString().padStart(5, '0') == VisitorID.toString());
      }

    }
    catch (error) {
      console.error("Error fetching visitor data:", error);
    }
  };


  const formValidation = () => {
    if (!visitor?.ExternalIDType) {
      alert('Please select type of ID');
    }
    else if (!visitor?.ExternalID) {
      alert(`Please enter ${visitor?.ExternalIDType} Number`);
    }
    else if (!visitor?.VisitorName) {
      alert('Please enter your name');
    }
    else if (!approvalFormData.ApprovalAuthority) {
      alert('Please enter approval authority');
      return false;
    }
    else if (!approvalFormData.BuildingAccess) {
      alert('Please enter building name');
      return false;
    }
    else if (!checkinFormData.VisitorPassNumber) {
      alert('Please enter visitor pass number');
      return false;
    }

    return true;
  }
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleLogout = () => {
    navigate("/logout");
  }

  return (
    <React.Fragment>
      <div style={userRole == 'Record Keeper' ? {padding: 0}:{}}>
        <Container fluid={true}>
          <Row>
            <Col sm={12} className='text-end'>
                <button ref={btnRef} type="button" className="btn header-item noti-icon waves-effect" onClick={() => {
                    toggleFullscreen()
                  }} data-bs-toggle="fullscreen">
                    <i className="mdi mdi-fullscreen"></i>
                </button>
              <button className='btn btn-danger' onClick={handleLogout}>Logout</button>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <FormGroup>
                <Label for="VisitorPass">Check out</Label>

                <Input type="text"
                  id="VisitorPass"
                  placeholder="Enter Visitor Pass"
                  value={VisitorPassNumber}
                  onChange={(e)=> setVisitorPassNumber(e.target.value)} />
              </FormGroup>
            </Col>
            <Col sm={4} style={{alignContent: 'center', marginTop: '10px'}}>
              <button className='btn btn-primary' onClick={checkOut}>Checkout</button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm={12}>
              <h5>Visitor Form<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
              </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>
            </Col>

            <Form>
            <Row>
              <Col md={3}>
                <FormGroup>
                    <Label for="VisitorName">Visitor Name</Label>

                    <Input type="text"
                      id="VisitorName"
                      placeholder="Enter Visitor Name"
                      value={visitor?.VisitorName}
                      onChange={handleVisitorChange}
                      required />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                    <Label for="VisitorAddress">Visitor Address</Label>

                    <Input type="text"
                      id="VisitorAddress"
                      placeholder="Enter Visitor Address"
                      value={visitor?.VisitorAddress}
                      onChange={handleVisitorChange}
                      required />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                    <Label for="ExternalIDType">Type of ID</Label>

                    <Input type="select"
                      id="ExternalIDType"
                      placeholder="Enter ID Type"
                      value={visitor?.ExternalIDType}
                      onChange={handleVisitorChange}
                      required>
                      <option>Passport</option>
                      <option>National ID</option>
                      <option>Driving License</option>
                    </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                    <Label for="ExternalID">ID Number</Label>

                    <Input type="text"
                      id="ExternalID"
                      placeholder="Enter ID Number"
                      value={visitor?.ExternalID}
                      onChange={handleVisitorChange}
                      required />
                </FormGroup>
              </Col>


              <hr />

              <h5 style={{ marginTop: "20px" }}>Approval Details</h5>
              <Col>
                <Row className="mb-4">
                  <Col md={4}>
                    <FormGroup>
                        <Label for="ApprovalAuthority">Approval Authority</Label>

                        <Input type="text"
                          id="ApprovalAuthority"
                          placeholder="Enter Approval Authority"
                          value={approvalFormData.ApprovalAuthority}
                          onChange={handleApprovalChange}
                          required>
                        </Input>
                    </FormGroup>
                  </Col>


                  <Col md={4}>
                    <FormGroup>
                      <Label for="ApprovalType">Approval Type</Label>
                      <Input type="select"
                        id="ApprovalType"
                        value={approvalFormData.ApprovalType}
                        onChange={handleApprovalChange}>
                        <option>Manual</option>
                        <option>Call</option>
                        <option>Email</option>
                      </Input>
                    </FormGroup>
                  </Col>

                </Row>
              </Col>

              <hr />

              <h5 style={{ marginTop: "20px" }}>Check-In Details</h5>


              <Col>
                <Row className="mb-4">
                  <Col md={3}>
                    <FormGroup>
                        <Label for="CheckinAuthority">Check-In Officer</Label>

                        <Input type="select"
                          id="CheckinAuthority"
                          value={checkinFormData.CheckinAuthority}
                          onChange={handleCheckinChange}
                          required>
                          <option>Help Desk Officer 1</option>
                          <option>Help Desk Officer 2</option>
                        </Input>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label for="VisitorPassNumber">Visitor Pass Number</Label>
                      <Input type="text"
                        id="VisitorPassNumber"
                        placeholder="Enter Pass Number"
                        value={checkinFormData.VisitorPassNumber}
                        onChange={handleCheckinChange}>
                      </Input>
                    </FormGroup>
                  </Col>


                  <Col md={3}>
                    <FormGroup>
                      <Label for="BuildingAccess">Building Access</Label>
                      <Input
                        type="text"
                        id="BuildingAccess"
                        placeholder="Enter Building Name"
                        value={approvalFormData.BuildingAccess}
                        onChange={handleApprovalChange}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={3}>
                    <Label for="purpose">Purpose of Visit</Label>
                    <Input type="select"
                      id="VisitPurpose"
                      value={approvalFormData.VisitPurpose}
                      onChange={handleApprovalChange}
                      required>
                      <option>Project Meeting</option>
                      <option>Interview</option>
                      <option>Official Meeting</option>
                      <option>Personal Visit</option>
                    </Input>
                  </Col>
                </Row>
                <Button color="primary" onClick={clickCheckin} style={{ float: "right" }} >
                  Check-In
                </Button>
              </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PublicVisitorForm;
