import React, {useState} from 'react';
import { Row, Col, Label, Input, Button, FormGroup } from "reactstrap";

const CheckinVisitorForm = ({toggle, visitorData, onSubmit}) => {

//   const [VisitorPass, setVisitorPass] = useState('');
  const [CheckinAuthority, setCheckinAuthority] = useState('Help Desk Officer 1');
//   const [CheckinOfficerRank, setCheckinOfficerRank] = useState('');

  const clickCheckIn = () => {

    let payload = {
        ApprovalID:         visitorData.ApprovalID,
        VisitorID:          visitorData.VisitorID,
        BuildingAccess:     visitorData.BuildingAccess,
        CheckinAuthority:   CheckinAuthority,
        // CheckinOfficerRank: CheckinOfficerRank,
        CheckinDate:        new Date().toLocaleDateString('en-US'), // Explicit locale for mac support
        CheckinTime:        new Date().toLocaleTimeString('en-US'), // Explicit locale for mac support
    }
    onSubmit(payload);
    toggle();
  };

  return (
    <>
        <h5>Check-In</h5>

        <hr />

        <h5>Visitor Details</h5>

        <Row>
            <Col sm={3}>
                <strong>Visitor ID:</strong> {visitorData?.VisitorID || ""}
            </Col>

            <Col sm={3}>
                <strong>Visitor Name:</strong> {visitorData?.visitor?.VisitorName || ""}
            </Col>

            <Col sm={3}>
                <strong>Approval Authority:</strong> {visitorData?.ApprovalAuthority || ""}
            </Col>

            <Col sm={3}>
                <strong>Approval Type:</strong> {visitorData?.ApprovalType || ""}
            </Col>
        </Row>


        <h5 className='mt-4'>Check-In Details</h5>

        <Col>
            <Row>
                <Col sm={8}>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <Label className='mt-2' for="CheckinAuthority">Check-In Officer</Label>
                            </Col>                            
                            <Col sm={5}>
                            <Input type="select"
                                id="CheckinAuthority"
                                value={CheckinAuthority}
                                onChange={(e) => setCheckinAuthority(e.target.value)}>
                            <option>Help Desk Officer 1</option>
                            <option>Help Desk Officer 2</option>
                        </Input>
                            </Col>                            
                        </Row>
                        
                    </FormGroup>
                </Col>
                {/* <Col sm={6}>
                    <FormGroup>
                        <Label for="CheckinOfficerRank">Check-In Officer Rank</Label>
                        <Input
                            type="text"
                            id="CheckinOfficerRank"
                            placeholder="Enter Checkin Officer Rank"
                            value={CheckinOfficerRank}
                            onChange={(e) => setCheckinOfficerRank(e.target.value)}
                        />
                    </FormGroup>
                </Col> */}
            </Row>
            <Button color="primary" style={{float:"right"}} onClick={clickCheckIn}>
                Check In
            </Button>
        </Col>
    </>
  );
};

export default CheckinVisitorForm;
