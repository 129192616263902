import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Tooltip,
    ModalHeader,
    ModalFooter
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { del, get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ToolTip, Legend } from 'recharts';
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";

import './project.css';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const KPIReportDetail = () => {
    document.title = "KPI Report Detail";

    const { id } = useParams();
    const chartRef = useRef(null);

    const [data, setData] = useState([]);
    const [PSComment, setPSComment] = useState("");
    const [Comment, setComment] = useState("");
    const [Status, setStatus] = useState("");
    const [ReportName, setReportName] = useState("");

    const [downloadModal, setDownloadModal] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const [userRole, setUserRole] = useState('');

    const handlePsModal = () => {
        if(!PSComment){
            setDownloadModal(true);
        }
        else{
            downloadChart();
        }
    }

    const downloadChart = () => {
        if (chartRef.current) {
          toPng(chartRef.current, { backgroundColor: 'white' })
            .then((dataUrl) => {
            //   const link = document.createElement('a');
            //   link.download = 'chart.png';
            //   link.href = dataUrl;
            //   link.click();
                const pdf = new jsPDF("p", "mm", "a4");
        
                const pdfWidth = pdf.internal.pageSize.getWidth();

                let dimenstion = chartRef.current.getBoundingClientRect();
                const pdfHeight = (dimenstion?.height * pdfWidth)/ dimenstion?.width;

                pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${ReportName || 'report'}.pdf`);
            })
            .catch((err) => {
              console.error('Failed to download chart:', err);
            });
        }
    };

    const getReportById = async () => {
        const data = await get(`kpi/report/find/${id}`);
        setPSComment(data?.PSComment);
        setComment(data?.Comment);
        setStatus(data?.Status);
        setReportName(data?.ReportName);
        setData(data?.kpiReportDetail || []);
    }

    const handleApproval = async (status) => {
        await put(`kpi/report/ps-approval/${id}`, {PSComment, Status: status});
        await getReportById();
        setPSComment("");
        setDownloadModal(false);
    }

    const handleDownload = async () => {
        if(Status == 'Approved' || Status == 'Downloaded'){
            await handleApproval("Downloaded");
            downloadChart();
        }
        else{
            toastr.error("Report Rejected by PS", "Error");
        }
    }

    useEffect(()=>{
        setUserRole(getLoggedInUserRole());
        getReportById();
    },[]);
    return (
        <React.Fragment>

            <div className="page-content">
                <h5 style={{paddingTop: '20px'}}>Monitoring & Evaluation</h5>
                <p>Monitoring & Evaluation <b>&gt;</b> Report <b>&gt;</b> Report Detail</p>
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <Row>
                        <Col sm={6}>
                            <h5>
                                KPI Report Detail
                                <i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
                            </h5>

                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="pageHeading"
                                toggle={toggleTooltip}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
                            </Tooltip>

                            </Col>
                    </Row>

                    

                    <Row className="mb-4 mt-4">
                        {data && data.length > 0 && <Col sm={12}>
                        {userRole == 'Permanent Secretary' &&<div className="text-end">
                            {Status && Status == 'Pending' && <button className="btn btn-primary" onClick={handlePsModal}>Approval Required</button>}
                            {Status && (Status == 'Approved' || Status == 'Downloaded') && <button className="btn btn-primary" onClick={handleDownload}>Download</button>}
                            {Status && Status == 'Rejected' && <button className="btn btn-danger" disabled>Rejected</button>}
                        </div>}
                            <div ref={chartRef} style={{placeItems: 'center', width: '100%'}}>
                                <BarChart
                                width={600}
                                height={400}
                                data={data}
                                margin={{
                                    top: 20, right: 30, left: 20, bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Name" />
                                <YAxis />
                                <ToolTip />
                                <Legend />
                                <Bar dataKey="TargetValue" fill="#8884d8" name="Target" />
                                <Bar dataKey="Value" fill="#82ca9d" name="Achieved" />
                                </BarChart>
                                <Row className="mt-4 w-100">
                                    <Col sm={6}>
                                        <FormGroup className="d-flex text-nowrap">
                                        <Label for="" className="me-4">Comment</Label>
                                        <textarea
                                            rows={5}
                                            cols={100}
                                            type="text"
                                            id=""
                                            disabled
                                            value={Comment} />
                                        </FormGroup>
                                    </Col>
                                    {PSComment && <Col sm={6}>
                                        <FormGroup className="d-flex text-nowrap">
                                        <Label for="" className="me-4">PS Comment</Label>
                                        <textarea
                                            rows={5}
                                            cols={100}
                                            type="text"
                                            id=""
                                            disabled
                                            value={PSComment} />
                                        </FormGroup>
                                    </Col>}
                                </Row>
                            </div>
                        </Col>}
                    </Row>
                    <Modal isOpen={downloadModal} toggle={()=>setDownloadModal(false)} size="lg">
                        <ModalHeader className="mt-0" toggle={()=> setDownloadModal(false)}>Download Report</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm={12} sx={{mb:1}}>
                                    <h5 className="mt-0">Approval</h5>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Comment</Label>
                                    <Input
                                        type="text"
                                        id=""
                                        placeholder="Comment"
                                        onChange={(e) => setPSComment(e.target.value)}
                                        value={PSComment} />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} className="text-end">
                                    <Row>
                                        <Col sm={6}>
                                            <button className="btn btn-primary" disabled={!PSComment} onClick={()=> handleApproval("Approved")}>Approve</button>
                                        </Col>
                                        <Col sm={6}>
                                            <button className="btn btn-danger mx-2" disabled={!PSComment} onClick={()=> handleApproval("Rejected")}>Reject</button>
                                        </Col>
                                    </Row>
                                    {/* <button className="btn btn-primary mt-5" disabled={!PSComment} onClick={handleDownload}>Download</button> */}
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default KPIReportDetail;
