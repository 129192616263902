import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
  Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader,
  Tooltip
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get, post, postForm } from "helpers/api_helper";
import moment from 'moment';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ToolTip, Legend } from 'recharts';
import { toPng } from "html-to-image";
import { DateRangePicker } from "react-dates";

const formatDateTime = (isoDate) => {
  return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const KPIReport = () => {
  document.title = "KPI Report";

  const navigate = useNavigate();

  const chartRef = useRef(null);

  const [data, setData] = useState([]);

  const startyear = 2024
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < (currentYear - 2015); i++) {
      years.push(startyear + i);
  }

  const [ReportName, setReportName] = useState("");
  const [Department, setDepartment] = useState("");
  const [KPIDefinitionID, setKPIDefinitionID] = useState();
  const [Year, setYear] = useState();
  const [Comment, setComment] = useState("");
  const [searchText, setSearchText] = useState("");
  const [reportModal, setReportModal] = useState(false);

  const [KPIMainList, setKPIMainList] = useState([]);
  const [KPIList, setKPIList] = useState([]);
  const [result, setResult] = useState([]);
  const [reportList, setReportList] = useState([]);

  const [isDateRange, setIsDateRange] = useState(false);

  const [focusedInput, setFocusedInput] = useState(null);
  const [StartDate, setStartDate] = useState(moment());
  const [EndDate, setEndDate] = useState(moment());

  const handleModal = () => {
    setKPIDefinitionID(null);
    setYear(null);
    setComment("");
    setResult([]);
    setReportModal(false);
  }

  const getKPI = async () => {
    const data = await get(`kpi/find`);
    setKPIMainList(data || []);
  }

  useEffect(()=>{
    setKPIList(KPIMainList.filter(x=> x.Department == Department));
  },[Department]);

  const getReport = async () => {
    const data = await get(`kpi/report/find`);
    setData(data || []);
  }

  useEffect(()=>{
    getKPI();
    getReport();
  },[]);

  const getKpiYear = async () => {
    const startDate = moment(StartDate).startOf('day').toISOString();
    const endDate = moment(EndDate).endOf('day').toISOString();
    const data = await post(`kpi/indicator/find-by-kpi-year`, {KPIDefinitionID, Year, StartDate: startDate, EndDate: endDate, isDateRange});
    setResult(data || []);
  }

  useEffect(()=>{
    if(KPIDefinitionID && (Year || (StartDate && EndDate))){
      getKpiYear();
    }
  },[KPIDefinitionID,Year, StartDate, EndDate]);

  const handleViewDetails = (row) => {
    navigate(`/kpi-report-detail/${row.ReportID}`);
  }

  const columns = useMemo(
    () => [
      {
        name: 'Report ID',
        selector: (row) => row?.ReportID,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Year',
        selector: (row) => row?.Year,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'KPI Definition',
        selector: (row) => row?.kpiDefinition?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Department',
        selector: row => row?.kpiDefinition?.Department,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Status',
        selector: row => row?.Status,
        sortable: true,
        enableSorting: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            color="primary"
            size="sm"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const paginatedData = data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const filteredData = searchText.trim() === ""
    ? data // If searchText is empty, return all data without filtering
    : data.filter(
      (item) =>
        item?.Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.HeadCost?.toString().includes(searchText) ||
        item?.Status?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.Amount?.toString().includes(searchText) ||
        (item.ps?.Name || "").toLowerCase().includes(searchText.toLowerCase())
    );

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData); // Convert data to sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append the sheet to the workbook
    XLSX.writeFile(wb, 'report_data.xlsx'); // Export as .xlsx file
  };

  const generateReport = async () => {
    const data = {
      KPIDefinitionID, Year, Comment, Department, ReportName, isDateRange,
      StartDate: moment(StartDate).startOf('day').toISOString(),
      EndDate: moment(EndDate).endOf('day').toISOString()
    };

    await post('kpi/report/generate', data);
    getReport();
    handleModal();
  }

  const downloadChart = () => {
    if (chartRef.current) {
      toPng(chartRef.current, { backgroundColor: 'white' })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'chart.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error('Failed to download chart:', err);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <h5 style={{paddingTop: '20px'}}>Monitoring & Evaluation</h5>
        <p>Monitoring & Evaluation <b>&gt;</b> Reports</p>
        <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
          <Row>
            <Col sm={6}>
              <h5>Reports<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
              </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>

            </Col>
            <Col sm={6} className="text-end"><Button color="primary" onClick={()=> setReportModal(true)}> <i className="fa fa-plus"></i> Generate Report </Button></Col>

            <Modal isOpen={reportModal} toggle={handleModal} size="lg">
              <ModalHeader className="mt-0" toggle={handleModal}>Generate Report</ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm={12} sx={{mb:1}}>
                    <h5 className="mt-0">Select KPI</h5>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="Report Name" className="me-4 w-50">Report Name</Label>
                      <Input
                        type="text"
                        id="Report Name"
                        placeholder="Report Name"
                        onChange={(e) => setReportName(e.target.value)}
                        value={ReportName}/>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Department</Label>
                      <Input
                        type="select"
                        id=""
                        placeholder="KPI"
                        onChange={(e) => setDepartment(e.target.value)}
                        value={Department}>
                          <option selected>Select Department</option>
                          <option value={"MoHA"}>MoHA</option>
                          <option value={"CANU"}>CANU</option>
                          <option value={"Forensic"}>Forensic</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">KPI Definition</Label>
                      <Input
                        type="select"
                        id=""
                        placeholder="KPI"
                        onChange={(e) => setKPIDefinitionID(e.target.value)}
                        value={KPIDefinitionID}
                      >
                        <option selected>Select KPI</option>
                        {KPIList.map(kpi => (
                            <option key={kpi.KPIDefinitionID} value={kpi.KPIDefinitionID}>
                                {kpi.Name}
                            </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  
                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Input
                        type="radio"
                        id="isYear"
                        placeholder="KPI"
                        onChange={() => setIsDateRange(false)}
                        value={isDateRange}
                        checked={isDateRange === false}
                      />
                      <Label for="isYear" className="me-4">Year</Label>
                      
                      <Input
                        type="radio"
                        id="isDateRange"
                        placeholder="KPI"
                        onChange={() => setIsDateRange(true)}
                        value={isDateRange}
                        checked={isDateRange === true}
                      />
                      <Label for="isDateRange" className="me-4">Date Range</Label>
                    </FormGroup>
                  </Col>

                  {!isDateRange && <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="year" className="me-4 w-50">Year</Label>
                      <Input type="select"
                          placeholder="Year"
                          id="year"
                          onChange={(e)=>setYear(e.target.value)}
                          value={Year}
                          required>
                              <option selected>Select Year</option>
                              {years.map(year => (
                                  <option key={year} value={year}>
                                      {year}
                                  </option>
                              ))}
                      </Input>
                    </FormGroup>
                  </Col>}

                  {isDateRange && <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="Date" className="me-2 w-50">Date Range </Label>
                      <DateRangePicker className="date-range-picker"
                          startDate={StartDate}
                          startDateId="start_date_id"
                          endDate={EndDate}
                          endDateId="end_date_id"
                          onDatesChange={({ startDate, endDate }) => {setStartDate(startDate); setEndDate(endDate)}}
                          focusedInput={focusedInput}
                          onFocusChange={(input) => setFocusedInput(input)}
                          displayFormat="DD/MM/YYYY"
                          isOutsideRange={() => false}
                      />
                    </FormGroup>
                  </Col>}

                  {result && result.length > 0 && <Col sm={12}>
                  {/* <div className="text-end">
                    <button className="btn btn-primary" onClick={downloadChart}>Download</button>
                  </div> */}
                  <div ref={chartRef} style={{placeItems: 'center'}}>
                    <BarChart
                      width={600}
                      height={400}
                      data={result}
                      margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Name" />
                      <YAxis />
                      <ToolTip />
                      <Legend />
                      <Bar dataKey="TargetValue" fill="#8884d8" name="Target" />
                      <Bar dataKey="Value" fill="#82ca9d" name="Achieved" />
                    </BarChart>
                  </div>
                  </Col>}

                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="comment" className="me-4">Comment</Label>
                      <Input type="text"
                          placeholder="Comment"
                          id="comment"
                          onChange={(e)=>setComment(e.target.value)}
                          value={Comment}
                          required />
                    </FormGroup>
                  </Col>
                  
                  <Col sm={12} className="text-end">
                    <button className="btn btn-primary mt-5" disabled={!Comment || !KPIDefinitionID} onClick={generateReport}>Generate</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Row>

          <Row className="mt-4">
            <Col sm={6}>
              <div className="table-subheader">
                <span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </span>
              </div>
            </Col>
            <Col sm={6}>
              <button className="btn btn-success" style={{float: "right"}} onClick={handleExport}>Export</button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                highlightOnHover
                striped
                responsive
              />
            </Col>
          </Row>
          <Row>
            <Pagination style={{ float: "right" }}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={handlePrevPage} />
              </PaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={currentPage == index + 1} key={index}>
                  <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
            </Pagination>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default KPIReport;
