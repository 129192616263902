import React from "react";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import VisitorManagement from "../pages/BuildingSecurity/VisitorManagement"
import VisitorCheckIn from "../pages/BuildingSecurity/VisitorCheckIn"
import VisitorCheckOut from "../pages/BuildingSecurity/VisitorCheckOut"
import VisitorLedger from "../pages/BuildingSecurity/VisitorLedger"
import ViewVisitorDetails from "../pages/BuildingSecurity/BuildingSecuritySetup/ViewVisitorDetails"
import YearlyExpense from "../pages/YearlyExpense/YearlyExpense"
import Projects from "../pages/Projects/Projects"
import Employee from "../pages/Employee/Employee"
import Vendor from "../pages/Vendor/Vendor"
import PaymentVoucher from "../pages/PaymentVoucher/PaymentVoucher"

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import AddYearlyExpense from "pages/YearlyExpense/AddYearlyExpense";
import ProjectDetail from "pages/Projects/ProjectDetail";
import UpdateYearlyExpense from "pages/YearlyExpense/UpdateYearlyExpense";
import PaymentVoucherDetail from "pages/PaymentVoucher/PaymentVoucherDetail";
import KPIDefinition from "pages/KPIDefinition/KPIDefinition";
import KPIDefinitionDetail from "pages/KPIDefinition/KPIDefinitionDetail";
import KPIReport from "pages/KPIReport/KPIReport";
import KPIReportDetail from "pages/KPIReport/KPIReportDetail";
import CaseManagement from "pages/CaseManagement/CaseManagement";
import CreateCase from "pages/CaseManagement/CreateCase";
import CaseDetails from "pages/CaseManagement/CaseDetails";
import PublicVisitorForm from "pages/BuildingSecurity/BuildingSecuritySetup/PublicVisitorForm";


const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },

  { path: "/visitor-management", component: <VisitorManagement /> },
  { path: "/visitor-check-in", component: <VisitorCheckIn /> },
  { path: "/visitor-check-out", component: <VisitorCheckOut /> },
  { path: "/visitor-ledger", component: <VisitorLedger /> },
  { path: "/visitor-details/:id", component: <ViewVisitorDetails /> },
  { path: "/yearly-expense", component: <YearlyExpense /> },
  { path: "/add-yearly-expense", component: <AddYearlyExpense /> },
  { path: "/yearly-expense/:id", component: <UpdateYearlyExpense /> },
  { path: "/projects", component: <Projects />},
  { path: "/project-detail/:id", component: <ProjectDetail />},
  { path: "/employee", component: <Employee />},
  { path: "/vendor", component: <Vendor />},
  { path: "/payment-voucher", component: <PaymentVoucher />},
  { path: "/payment-voucher-detail/:id", component: <PaymentVoucherDetail/> },
  { path: "/kpi-definition", component: <KPIDefinition />},
  { path: "/kpi-definition-detail/:id", component: <KPIDefinitionDetail />},
  { path: "/kpi-report", component: <KPIReport />},
  { path: "/kpi-report-detail/:id", component: <KPIReportDetail />},
  { path: "/case-management", component: <CaseManagement />},
  { path: "/create-case", component: <CreateCase />},
  { path: "/case-detail/:id", component: <CaseDetails />},

  { path: "/visitor-self-checkin", component: <PublicVisitorForm />}
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },  
];

export { userRoutes, authRoutes };
