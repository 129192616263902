import React, { useEffect, useMemo, useState } from "react";
import {Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup, Label, Input, Pagination, PaginationItem,PaginationLink} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CheckinVisitorForm from "./BuildingSecuritySetup/CheckinVisitorForm";
import ReapproveVisitor from "./BuildingSecuritySetup/ReapproveVisitorForm";
import { get, post } from "../../../src/helpers/api_helper";
import { getLoggedInUserRole } from "helpers/fakebackend_helper";

const VisitorCheckIn = () => {
  const [checkinData, setCheckinData] = useState([]);
  const [modal, setModal] = useState(false);
  let [visitorData, setVisitorData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [VisitorName, setVisitorName] = useState("");
  const [VisitorIDNumber, setVisitorIDNumber] = useState("");

  const [modalNewCheckin, setModalNewCheckin] = useState(false);
  const toggleNewCheckinModal = () => setModalNewCheckin(!modalNewCheckin); 

  const [approveModal, setApproveModal] = useState(false);
  const toggleApproveModal = () => setApproveModal(!approveModal);

  const handleFormSubmit = async (formData) => {
    try {
      await post("checkIn/create", formData);
      alert("Checked In successfully!");
      setModal(false);
      fetchCheckin();
    } 
    catch (error) {
      console.error("Error In Checkin:", error);
      alert("Failed to checkin Visitor.");
    }
  };


  const handleApproveFormSubmit = async (formData) => {
    try {
      let approveVisitor = await put("approvals/update/" + selectedVisitorData.ApprovalID, formData);
      alert("Visitor approved successfully!");
      setApproveModal(false);
      fetchVisitors();
    } catch (error) {
      console.error("Error approving visitor:", error);
      alert("Failed to approve visitor.");
    }
  };



  const clickCheckIn = (row) => {
    setVisitorData(row);
    setModal(!modal);
  };

  const clickApprove = (row) => {
    // setVisitorData(row);
    toggleApproveModal();
  };

  const fetchCheckin = async () => {
    try {
      let data = await get("approvals/findCheckin");
      data = data.filter((item) => item.ApprovalStatus == "Approved");
      setCheckinData(data);
    } 
    catch (error) {
      console.error("Error fetching checkin data:", error);
    }
  };

  useEffect(() => {
    fetchCheckin();
  }, []);

  const getValue = (row, accessor) => {
    if (typeof accessor == "function") {
      return accessor(row);
    }
    return row[accessor] || "";
  };

  const columns = useMemo(
    () => [
      {
        header: "Check-In Time",
        accessor: (row) => row.checkIn ? `${row.checkIn.CheckinDate || ""} ${row.checkIn.CheckinTime || ""}` : "",
      },
      { header: "Visitor ID", accessor: "VisitorID" },
      {
        header: "Visitor Name",
        accessor: (row) => row.visitor ? `${row.visitor.VisitorName || ""}` : "",
      },
      {
        header: "Visitor Pass",
        accessor: (row) => row.checkIn ? `${row.checkIn.VisitorPass || ""}` : "",
      },
      { header: "Visit Purpose", accessor: "VisitPurpose" },
      {
        header: "Check-In Officer",
        accessor: (row) => row.checkIn ? `${row.checkIn.CheckinAuthority || ""}` : "",
      },
      // {
      //   header: "Check-In Officer Rank",
      //   accessor: (row) => row.checkIn ? `${row.checkIn.CheckinOfficerRank || ""}` : "",
      // },
      { header: "Building Access", accessor: "BuildingAccess" },
      { header: "Actions", accessor: "actions" },
    ],
    []
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = checkinData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(checkinData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const searchVisitor = async () => {
    try {
      let data = await get("approvals/find");

      if (VisitorName && VisitorName.length > 0) {
        data = data.filter(item => item.visitor.VisitorName.toLowerCase().includes(VisitorName.toLowerCase()));
      }

      if (VisitorIDNumber && VisitorIDNumber.length > 0) {
        data = data.filter(item => item.visitor.ExternalID.toString().includes(VisitorIDNumber.toString()));
      }

      data = data.filter((item) => item.ApprovalStatus == "Approved");
      setCheckinData(data);
    } 
    catch (error) {
      console.error("Error fetching visitor data:", error);
    }
  };

  document.title = "Visitor CheckIn | Building Security";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="align-items-center">
            <Col sm={6}>
              <Breadcrumbs title="Building Security" breadcrumbItem="Visitor Check-In" />
            </Col>

            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Button onClick={clickApprove}  color="primary"  className="btn btn-primary waves-effect waves-light">
                  <i className="fa fa-plus" style={{ marginRight: "10px" }}></i>
                  Add Check-In
                </Button>
              </div>
            </Col>
          </Row>

          {/* Modal */}
          <Modal isOpen={modal} toggle={clickCheckIn} size="xl">
            <ModalBody style={{padding:"30px"}}>
              <CheckinVisitorForm toggle={clickCheckIn} visitorData={visitorData} onSubmit={handleFormSubmit} />
            </ModalBody>
          </Modal>

          {/* <Modal isOpen={modalNewCheckin} toggle={toggleNewCheckinModal} size="lg">
            <ModalBody>
              <ApproveVisitor toggle={toggleNewCheckinModal} visitorData={visitorData} onSubmit={handleApproveFormSubmit} />
            </ModalBody>
          </Modal> */}

          <Modal isOpen={approveModal} toggle={toggleApproveModal} size="xl">
            <ModalBody style={{padding:"30px"}}>
              <ReapproveVisitor
                toggle={toggleApproveModal}
                visitorData={visitorData}
                onSubmit={handleFormSubmit}
              />
            </ModalBody>
          </Modal>

          {/* Table Section */}
          <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
            <h5 style={{ fontSize: "1rem" }}>Check-In</h5>


            <Row className="mb-4 mt-4">
              <Col sm={3}>
                <FormGroup>
                  <Label for="VisitorName">Visitor Name</Label>
                  <Input
                    type="text"
                    id="VisitorName"
                    placeholder="Enter Visitor Name"
                    value={VisitorName}
                    onChange={(e) => setVisitorName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="VisitorIDNumber">ID Number</Label>
                  <Input
                    type="text"
                    id="VisitorIDNumber"
                    placeholder="Enter ID Number"
                    value={VisitorIDNumber}
                    onChange={(e) => setVisitorIDNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button color="primary" style={{ marginTop: "30px" }} onClick={searchVisitor}>
                  Search
                </Button>
              </Col>
            </Row>



            <Table bordered responsive style={{ fontSize: "0.8rem", tableLayout: "fixed", wordWrap: "break-word" }}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} style={{ fontSize: "0.9rem" }}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex} style={{ fontSize: "0.8rem" }}>
                        {column.accessor == "actions" ? (
                          <div>
                            <button disabled={row.checkIn} className="btn btn-success btn-sm" onClick={() => clickCheckIn(row)} style={{ marginRight: "5px" }}>
                              {row.checkIn? 'Checked In' : 'Check In'}
                            </button>
                          </div>
                        ) : (
                          getValue(row, column.accessor) // Call accessor function or retrieve value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>
            {/* Pagination Section */}
            <Pagination aria-label="Page navigation example" style={{float:"right"}}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem key={index} active={currentPage == index + 1}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
            </Pagination>
            </Row>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VisitorCheckIn;
