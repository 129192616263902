import { get, post, put } from "helpers/api_helper";
import { getLoggedInUserRole } from "helpers/fakebackend_helper";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader, Tooltip
} from "reactstrap";
import * as XLSX from 'xlsx';

const Employee = () => {
    document.title = "Employee";

    const [data, setData] = useState([]);
    const [role, setRole] = useState({});
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [Department, setDepartment] = useState('');
    const [Designation, setDesignation] = useState('');
    const [Contact, setContact] = useState('');

    const [add_employee_modal, setadd_employee_modal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // Track if it's edit mode
    const [selectedEmployee, setSelectedEmployee] = useState(null); // Track the selected employee for editing

    // Table columns
    const columns = [
        {
            name: 'Employee ID',
            selector: (row) => row.UserPrefixId,
            sortable: true,
            enableSorting: true,
        },
        {
            name: "Employee Name",
            selector: (row) => row.Name,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row.Designation,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.Department,
            sortable: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Contact,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
            sortable: true,
        },


        {
            name: "Action",
            cell: (row) => (
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleViewDetails(row)}
                >
                    View Details
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const fetchRole = async () => {
        const role = await get(`role/find-by-name/Employee`);
        setRole(role);
    };

    const fetchEmployee = async () => {
        const data = await get(`user/find-by-role-name/Employee`);
        setData(data);
    };

    const addEmployee = async () => {
        const data = {
            Name, Email, Password, Department, Designation, Contact, RoleID: role.RoleID,
        };
        await post(`user/create`, data);
        setadd_employee_modal(false);
        fetchEmployee();
    };

    const editEmployee = async () => {
        const data = {
            Name, Email, Department, Designation, Contact,
        };
        await put(`user/update/${selectedEmployee.UserID}`, data); // Example endpoint
        setadd_employee_modal(false);
        fetchEmployee();
    };

    const handleAddEmployee = () => {
        setIsEditMode(false);
        clearFields();
        setadd_employee_modal(true);
    };

    const handleViewDetails = (rowData) => {
        setIsEditMode(true);
        setSelectedEmployee(rowData);
        populateFields(rowData);
        setadd_employee_modal(true);
    };

    const clearFields = () => {
        setName('');
        setEmail('');
        setPassword('');
        setDepartment('');
        setDesignation('');
        setContact('');
    };

    const populateFields = (rowData) => {
        setName(rowData.Name);
        setEmail(rowData.Email);
        setDepartment(rowData.Department);
        setDesignation(rowData.Designation);
        setContact(rowData.Contact);
    };

    useEffect(() => {
        fetchRole();
        fetchEmployee();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleExport = () => {
        const ws = XLSX.utils.json_to_sheet(filteredData); // Convert data to sheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append the sheet to the workbook
        XLSX.writeFile(wb, 'employee_data.xlsx'); // Export as .xlsx file
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <Row>
                        <Col sm={6}>
                            <h5>Employee</h5>
                        </Col>
                        <Col sm={6} className="text-end">
                            <Button color="primary" onClick={handleAddEmployee}>
                                Add Employee
                            </Button>
                        </Col>
                    </Row>

                    <Modal isOpen={add_employee_modal} toggle={() => setadd_employee_modal(!add_employee_modal)} size="lg">
                        <ModalHeader toggle={() => setadd_employee_modal(!add_employee_modal)}>
                            {isEditMode ? "Update Employee" : "Add Employee"}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Employee Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setName(e.target.value)}
                                            value={Name}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Designation</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setDesignation(e.target.value)}
                                            value={Designation}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Contact</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setContact(e.target.value)}
                                            value={Contact}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Department</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setDepartment(e.target.value)}
                                            value={Department}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input
                                            type="email"
                                            placeholder="Type here..."
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={Email}
                                        />
                                    </FormGroup>
                                </Col>
                                {!isEditMode && (
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Password</Label>
                                            <Input
                                                type="password"
                                                placeholder="Type here..."
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={Password}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                                <Col sm={12} className="text-end">
                                    <Button color="primary" onClick={isEditMode ? editEmployee : addEmployee}>
                                        {isEditMode ? "Save" : "Submit"}
                                    </Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>

                    <Row className="mt-4">
                        <Col sm={2}>
                            <div className="table-subheader">
                                <span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                        }}
                                    />
                                </span>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <button className="btn btn-success" style={{float: "right"}} onClick={handleExport}>Export</button>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={30}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                highlightOnHover
                                striped
                                responsive
                                // subHeader
                                // subHeaderAlign="left"
                                // subHeaderComponent={
                                //     <div className="table-subheader">
                                //         <span>
                                //             <input
                                //                 type="text"
                                //                 className="form-control"
                                //                 placeholder="Search here..."
                                //                 onChange={(e) => {
                                //                     setSearchText(e.target.value)
                                //                 }}
                                //             />
                                //         </span>
                                //     </div>
                                // }
                            />
                        </Col>
                    </Row>

                    <Row>

                        <Pagination style={{ float: "right" }}>
                            <PaginationItem disabled={currentPage == 1}>
                                <PaginationLink previous onClick={handlePrevPage} />
                            </PaginationItem>

                            {[...Array(totalPages)].map((_, index) => (
                                <PaginationItem active={currentPage == index + 1} key={index}>
                                    <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                                        {index + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}

                            <PaginationItem disabled={currentPage == totalPages}>
                                <PaginationLink next onClick={handleNextPage} />
                            </PaginationItem>
                        </Pagination>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default Employee;
